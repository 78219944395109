import React, { useRef, useState } from 'react';
import './Tab_compo_port.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

const Tab_compo = () => {

    const ecom_mob_app = [
        {
            logo: "/assets/images/portfolio/project-logo/boomerbucks.png",
            image: "/assets/images/portfolio/mob-application/Boomerbucks.png",
            link: "#",
            content: "We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers."
        },
        {
            logo: "/assets/images/portfolio/project-logo/boomerbucks.png",
            image: "/assets/images/portfolio/mob-application/Boomerbucks.png",
            link: "#",
            content: "We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers."
        },
        {
            logo: "/assets/images/portfolio/project-logo/boomerbucks.png",
            image: "/assets/images/portfolio/mob-application/Boomerbucks.png",
            link: "#",
            content: "We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers."
        },

    ];

    const mob_game_app = [
        {
            image: "/assets/images/portfolio/game-application/game1.png"
        },
        {
            image: "/assets/images/portfolio/game-application/game1.png"
        },
        {
            image: "/assets/images/portfolio/game-application/game1.png"
        },
        {
            image: "/assets/images/portfolio/game-application/game1.png"
        },

    ];

    const social_media_app = [
        {
            logo: "/assets/images/portfolio/project-logo/boomerbucks.png",
            image: "/assets/images/portfolio/mob-application/layers/layer.png",
            content: "We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers."
        },
        {
            logo: "/assets/images/portfolio/project-logo/boomerbucks.png",
            image: "/assets/images/portfolio/mob-application/layers/layer.png",
            content: "We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers."
        },

    ];

    return (
        <>
            <section className='Tab_compo_port'>
                <div className='container'>
                    <div className='text-center'>
                        <h1 className='whotitle'>Portfolio</h1>
                        <h5 className='secsub-title'></h5>
                    </div>

                    <div className='tabsec'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="mobapp">
                            <Nav variant="pills" className="flex-row">
                                <Nav.Item>
                                    <Nav.Link eventKey="mobapp">Mobile Application</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="website">Website</Nav.Link>
                                </Nav.Item>
                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="mobapp">
                                    <h5 className='secsub-title my-4'>Our E-commerce Mob applications</h5>

                                    <div className='row my-5'>
                                        {ecom_mob_app.map((data, index) => (
                                            <div className='col-lg-4 col-sm-6 col-10 mx-auto mb-4'>
                                                <Link to={data.link}>
                                                    <div className='project-card'>
                                                        <div className='logodiv'>
                                                            <img className='logo' src={process.env.PUBLIC_URL + data.logo} />
                                                        </div>

                                                        <div className='image-div'>
                                                            <img className='projimg' src={process.env.PUBLIC_URL + data.image} />
                                                        </div>
                                                        <div className='cont-div'>
                                                            <p className='content'>{data.content}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>

                                    <div className='Mobile_gaming_sec'>
                                        <div className='bg-div'>
                                            <h5 className='secsub-title my-4 ms-5'>Our Mobile Gaming Applications</h5>

                                            <div className='slider-sec'>
                                                <Swiper navigation={true}
                                                    modules={[Navigation]}
                                                    className="mySwiper"
                                                    breakpoints={{
                                                        '0': {
                                                            slidesPerView: 1,
                                                            spaceBetween: 10,
                                                        },
                                                        '575': {
                                                            slidesPerView: 2,
                                                            spaceBetween: 20,
                                                        },
                                                        '768': {
                                                            slidesPerView: 3,
                                                            spaceBetween: 20,
                                                        },
                                                        '1400': {
                                                            slidesPerView: 3,
                                                            spaceBetween: 20,
                                                        },
                                                    }}
                                                >
                                                    {mob_game_app.map((data, index) => (
                                                        <SwiperSlide>
                                                            <div className='slider-div'>
                                                                <img className='gameimg' src={process.env.PUBLIC_URL + data.image} />
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='ecommob-sec'>
                                        <h5 className='secsub-title my-4'>Our Social Mob applications</h5>
                                        <div className='row'>
                                            {social_media_app.map((data, index) => (
                                                <div className=' col-md-6 col-12 mx-auto mb-4'>
                                                    <div className='project-card'>
                                                        <div className='logodiv'>
                                                            <img className='logo' src={process.env.PUBLIC_URL + data.logo} />
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-6 my-auto'>
                                                                <div className='cont-div'>
                                                                    <p className='content'>{data.content}</p>
                                                                </div>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className='image-div'>
                                                                    <img className='projimg' src={process.env.PUBLIC_URL + data.image} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </Tab.Pane>

                                <Tab.Pane eventKey="website">
                                    <h5 className='secsub-title my-4'>Our E-commerce Websites</h5>
                                    <div className='row my-5'>
                                        <div className='col-md-6 mb-4'>
                                            <div className='website-card'>
                                                <div className='logo-div'>
                                                    <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/mansa.png'} />
                                                </div>
                                                <div className='img-div'>
                                                    <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website/mansa-nutri.png'} />
                                                </div>
                                                <div className='content-div'>
                                                    <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-6 mb-4'>
                                            <div className='website-card'>
                                                <div className='logo-div'>
                                                    <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/cmj.png'} />
                                                </div>
                                                <div className='img-div'>
                                                    <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website/cmj.png'} />
                                                </div>
                                                <div className='content-div'>
                                                    <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-6 mb-4'>
                                            <div className='website-card'>
                                                <div className='logo-div'>
                                                    <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/mansa.png'} />
                                                </div>
                                                <div className='img-div'>
                                                    <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website/BoomerBucks_website.png'} />
                                                </div>
                                                <div className='content-div'>
                                                    <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-6 mb-4'>
                                            <div className='website-card'>
                                                <div className='logo-div'>
                                                    <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/cmj.png'} />
                                                </div>
                                                <div className='img-div'>
                                                    <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website/Chaudhary_website-Mockup.png'} />
                                                </div>
                                                <div className='content-div'>
                                                    <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-6 mb-4'>
                                            <div className='website-card'>
                                                <div className='logo-div'>
                                                    <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/mansa.png'} />
                                                </div>
                                                <div className='img-div'>
                                                    <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website/Coinzcart_website-mockup.png'} />
                                                </div>
                                                <div className='content-div'>
                                                    <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-6 mb-4'>
                                            <div className='website-card'>
                                                <div className='logo-div'>
                                                    <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/cmj.png'} />
                                                </div>
                                                <div className='img-div'>
                                                    <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website/FnS_Website_mockup.png'} />
                                                </div>
                                                <div className='content-div'>
                                                    <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-6 mb-4'>
                                            <div className='website-card'>
                                                <div className='logo-div'>
                                                    <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/mansa.png'} />
                                                </div>
                                                <div className='img-div'>
                                                    <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website/mansa-nutri.png'} />
                                                </div>
                                                <div className='content-div'>
                                                    <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-6 mb-4'>
                                            <div className='website-card'>
                                                <div className='logo-div'>
                                                    <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/cmj.png'} />
                                                </div>
                                                <div className='img-div'>
                                                    <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website/Hi_Tech-Solutions_Website.png'} />
                                                </div>
                                                <div className='content-div'>
                                                    <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-6 mb-4'>
                                            <div className='website-card'>
                                                <div className='logo-div'>
                                                    <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/mansa.png'} />
                                                </div>
                                                <div className='img-div'>
                                                    <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website/Icon_Cabs_Website_Mockup.png'} />
                                                </div>
                                                <div className='content-div'>
                                                    <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-6 mb-4'>
                                            <div className='website-card'>
                                                <div className='logo-div'>
                                                    <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/cmj.png'} />
                                                </div>
                                                <div className='img-div'>
                                                    <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website/IT-Pulse_Website_Mockup.png'} />
                                                </div>
                                                <div className='content-div'>
                                                    <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-6 mb-4'>
                                            <div className='website-card'>
                                                <div className='logo-div'>
                                                    <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/cmj.png'} />
                                                </div>
                                                <div className='img-div'>
                                                    <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website/L-KOUMODAKI_Website.png'} />
                                                </div>
                                                <div className='content-div'>
                                                    <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-6 mb-4'>
                                            <div className='website-card'>
                                                <div className='logo-div'>
                                                    <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/cmj.png'} />
                                                </div>
                                                <div className='img-div'>
                                                    <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website/mansa-nutri.png'} />
                                                </div>
                                                <div className='content-div'>
                                                    <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-6 mb-4'>
                                            <div className='website-card'>
                                                <div className='logo-div'>
                                                    <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/cmj.png'} />
                                                </div>
                                                <div className='img-div'>
                                                    <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website/Netpurti_Website-Mocckup.png'} />
                                                </div>
                                                <div className='content-div'>
                                                    <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-6 mb-4'>
                                            <div className='website-card'>
                                                <div className='logo-div'>
                                                    <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/cmj.png'} />
                                                </div>
                                                <div className='img-div'>
                                                    <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website/Shubharambh_Website_mockup.png'} />
                                                </div>
                                                <div className='content-div'>
                                                    <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-6 mb-4'>
                                            <div className='website-card'>
                                                <div className='logo-div'>
                                                    <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/cmj.png'} />
                                                </div>
                                                <div className='img-div'>
                                                    <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website/Sllume_Website_mockup.png'} />
                                                </div>
                                                <div className='content-div'>
                                                    <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-6 mb-4'>
                                            <div className='website-card'>
                                                <div className='logo-div'>
                                                    <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/cmj.png'} />
                                                </div>
                                                <div className='img-div'>
                                                    <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website/SV-Packaging_Website.png'} />
                                                </div>
                                                <div className='content-div'>
                                                    <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                </div>
                                            </div>
                                        </div>



                                    </div>

                                    <div className='Mobile_gaming_sec'>
                                        <div className='bg-div'>
                                            <h5 className='secsub-title my-4 ms-5'>Our Mobile Gaming Websites</h5>

                                            <div className='slider-sec'>
                                                <Swiper navigation={true}
                                                    modules={[Navigation]}
                                                    className="mySwiper"
                                                    breakpoints={{
                                                        '0': {
                                                            slidesPerView: 1,
                                                            spaceBetween: 10,
                                                        },
                                                        '575': {
                                                            slidesPerView: 2,
                                                            spaceBetween: 20,
                                                        },
                                                        '768': {
                                                            slidesPerView: 3,
                                                            spaceBetween: 20,
                                                        },
                                                        '1400': {
                                                            slidesPerView: 3,
                                                            spaceBetween: 20,
                                                        },
                                                    }}
                                                >
                                                    <SwiperSlide>
                                                        <div className='slider-div'>
                                                            <img className='gameimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/game-website/game1.png'} />
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className='slider-div'>
                                                            <img className='gameimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/game-website/game1.png'} />
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className='slider-div'>
                                                            <img className='gameimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/game-website/game1.png'} />
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className='slider-div'>
                                                            <img className='gameimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/game-website/game1.png'} />
                                                        </div>
                                                    </SwiperSlide>
                                                </Swiper>
                                            </div>

                                        </div>
                                    </div>


                                    <div className='ourweb-land-sec'>
                                        <h5 className='secsub-title my-4'>Our Websites Landing pages</h5>

                                        <div className='row my-5'>
                                            <div className='col-lg-4 col-sm-6 mb-4'>
                                                <div className='website-card'>
                                                    <div className='logo-div'>
                                                        <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/fyc.png'} />
                                                    </div>
                                                    <div className='content-div'>
                                                        <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                    </div>
                                                    <div className='img-div'>
                                                        <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website-landingpage/landingpage.png'} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-sm-6 mb-4'>
                                                <div className='website-card'>
                                                    <div className='logo-div'>
                                                        <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/fyc.png'} />
                                                    </div>
                                                    <div className='content-div'>
                                                        <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                    </div>
                                                    <div className='img-div'>
                                                        <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website-landingpage/landingpage.png'} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-sm-6 mb-4'>
                                                <div className='website-card'>
                                                    <div className='logo-div'>
                                                        <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/fyc.png'} />
                                                    </div>
                                                    <div className='content-div'>
                                                        <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                    </div>
                                                    <div className='img-div'>
                                                        <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website-landingpage/Short-Up_Landing-Page.jpg'} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-sm-6 mb-4'>
                                                <div className='website-card'>
                                                    <div className='logo-div'>
                                                        <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/fyc.png'} />
                                                    </div>
                                                    <div className='content-div'>
                                                        <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                    </div>
                                                    <div className='img-div'>
                                                        <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website-landingpage/landingpage.png'} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-sm-6 mb-4'>
                                                <div className='website-card'>
                                                    <div className='logo-div'>
                                                        <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/fyc.png'} />
                                                    </div>
                                                    <div className='content-div'>
                                                        <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                    </div>
                                                    <div className='img-div'>
                                                        <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website-landingpage/landingpage.png'} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-sm-6 mb-4'>
                                                <div className='website-card'>
                                                    <div className='logo-div'>
                                                        <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/portfolio/project-logo/fyc.png'} />
                                                    </div>
                                                    <div className='content-div'>
                                                        <p className='content'>We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.</p>
                                                    </div>
                                                    <div className='img-div'>
                                                        <img className='webimg' src={process.env.PUBLIC_URL + '/assets/images/portfolio/website-landingpage/landingpage.png'} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Tab.Pane>


                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Tab_compo