import React from 'react'

const Service_title = () => {
    return (
        <>
            <div className='text-center'>
                <h1 className='whotitle'>IT Solutions</h1>
                <h5 className='secsub-title'>Empowering Innovation, Simplifying Solutions</h5>
            </div>
        </>
    )
}

export default Service_title