import React from 'react'
import './Doubt_sec.css'
import Contact_form from '../../contact-us/contact-form/Contact_form'

const Doubt = () => {
    return (
        <>
            <section className='Doubt_sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 mx-auto'>
                            <div className='banner-bg'>
                                <div className='row'>
                                    <div className='col-md-5'>
                                        <div className='sec1'>
                                            <h1 className='ifyou'>If you have any Doubts</h1>
                                            <h1 className='then'>then</h1>

                                            <h3 className='clear'>Clear Your Doubts, Connect with Us</h3>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <Contact_form />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Doubt