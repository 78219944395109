import React from "react";
import "./Development_Process_Tab_Content_Card.css";

function Development_Process_Tab_Content_Card({
  imgSrc,
  numberText,
  proccesName,
  listContent,
}) {
  return (
    <>
      <section className="Development_Process_Tab_Content_Card">
        <div className="row">
          <div className="col-md-4">
            <div className="img-holder">
              <img
                className="Development_Process_img"
                src={process.env.PUBLIC_URL + imgSrc}
                alt="Development_Process_img"
              />
            </div>
          </div>

          <div className="col-md-8">
            <div className="box-number">
              <p className="numbtext">{numberText}</p>
            </div>

            <div className="text-holder">
              <div className="heading-holder">
                <h4>
                  {" "}
                  <div className="span_holder"><p>{numberText}</p></div> {proccesName}
                </h4>
              </div>
              <div className="list-holder">{listContent}</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Development_Process_Tab_Content_Card;
