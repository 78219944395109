import React from "react";
import "./Service_development_Card.css";

function Service_development_Card({headingText, descriptionText, imgSrc }) {
  return (
    <>
      <div className="Service_development_Card">
        <div className="Card_img_holder">
          <img
            className="Service_development_Card_img"
            src={process.env.PUBLIC_URL + imgSrc}
            alt="Service_development"
          />
        </div>
        <div className="text_holder">
          <h4>{headingText}</h4>

          <p>
            {descriptionText}
          </p>
        </div>
      </div>
    </>
  );
}

export default Service_development_Card;
