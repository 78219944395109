import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Nav, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Development_Process_Tab_Content_Card from "../../../../Common_Component/Development_Process_Tab_Content_Card/Development_Process_Tab_Content_Card";

const tabsData = [
  {
    key: "first",
    title: "Strategy & Planning",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/1.png",
    numberText: "1",
    processName: "Strategy & Planning",
    listContent: (
      <>
        <ul>
          <li>
            - We start by understanding your business goals, target audience, and competition to create a tailored digital marketing strategy that drives measurable results.
          </li>          
        </ul>
      </>
    ),
  },
  {
    key: "second",
    title: "Content Creation & Optimization",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/2.png",
    numberText: "2",
    processName: "Content Creation & Optimization",
    listContent: (
      <>
        <ul>
          <li>
            - Our team develops engaging content that resonates with your audience, while optimizing it for SEO, ensuring maximum reach and visibility across digital channels.
          </li>          
        </ul>
      </>
    ),
  },
  {
    key: "third",
    title: "Campaign Execution",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/3.png",
    numberText: "3",
    processName: "Campaign Execution",
    listContent: (
      <>
        <ul>
          <li>
            - We implement multi-channel campaigns (PPC, social media, email marketing, etc.) designed to drive traffic, generate leads, and increase conversions.
          </li>
          
        </ul>
      </>
    ),
  },
  {
    key: "fourth",
    title: "Analytics & Monitoring",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/4.png",
    numberText: "4",
    processName: "Analytics & Monitoring",
    listContent: (
      <>
        <ul>
          <li>
            - We continuously track and analyze campaign performance, using data-driven insights to adjust strategies and improve results in real-time.
          </li>
          
        </ul>
      </>
    ),
  },
  {
    key: "fifth",
    title: "Reporting & Optimization",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/5.png",
    numberText: "5",
    processName: "Reporting & Optimization",
    listContent: (
      <>
        <ul>
          <li>
            - We provide detailed reports on key performance metrics, offering actionable insights and refining campaigns for sustained growth and ROI.
          </li>
          
        </ul>
      </>
    ),
  },
  {
    key: "sixth",
    title: "Deployment & Support",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/6.png",
    numberText: "6",
    processName: "Deployment & Support",
    listContent: (
      <>
        <ul>
          <li>
            - Launch the website on the specified cloud or server according to
            requirements
          </li>
          <li>- Preparing Metadata</li>
          <li>- Submitting the Code to Stores</li>
          <li>- Ongoing Support and Performance Monitoring</li>
        </ul>
      </>
    ),
  },
];

function Development_Process_Tab() {
  return (
    <section className="Development_Process_Tab">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className="row me-0 ms-0">
          <div className="col-lg-5 desktop-view-tab-holder">
            <Nav variant="pills" className="flex-column">
              {tabsData.map(({ key, title }) => (
                <Nav.Item key={key}>
                  <Nav.Link eventKey={key}>
                    <div className="tab-name-holder">
                      <p>{title}</p>
                      <div className="arrow-holder">
                        <FontAwesomeIcon
                          className="arrow-icon"
                          icon={faAngleRight}
                        />
                      </div>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>

          <div className="col-md-12 mobile-view-tab-holder">
            <Nav variant="pills" className="tabs-nav">
              <Swiper
                modules={[Navigation]}
                spaceBetween={10}
                slidesPerView={3} // Adjust based on how many tabs should be visible
                navigation
                pagination={{ clickable: true }}
                className="tabs-swiper"
                breakpoints={{
                  0: {
                    // For small screens
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },

                  486: {
                    // For small screens
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },

                  575: {
                    // For small screens
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },
                  768: {
                    // For medium screens
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                }}
              >
                {tabsData.map(({ key, title }) => (
                  <SwiperSlide key={key}>
                    <Nav.Item>
                      <Nav.Link eventKey={key}>
                        <div className="tab-name-holder">
                          <p>{title}</p>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Nav>
          </div>

          <div className="col-lg-7 col-md-12 tab-content-holder">
            <Tab.Content>
              {tabsData.map(
                ({ key, imgSrc, numberText, processName, listContent }) => (
                  <Tab.Pane eventKey={key} key={key}>
                    <Development_Process_Tab_Content_Card
                      imgSrc={imgSrc}
                      numberText={numberText}
                      proccesName={processName}
                      listContent={listContent}
                    />
                  </Tab.Pane>
                )
              )}
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </section>
  );
}

export default Development_Process_Tab;
