import React, { useEffect } from "react";
import "./banner/Banner.css";
import Banner from "./banner/Banner";
import Grow_Your_Business from "./Grow_Your_Business/Grow_Your_Business";
import We_are_avers from "./we-are-avers/We_are_avers";
import Why_choose from "./why-choose/Why_choose";
import How_we_work from "./how-we-work/How_we_work";
import Want_discuss from "./want-discuss/Want_discuss";
import Tech_innovation from "./tech_innovation/Tech_innovation";
import Testimonials from "./testimonials/Testimonials";
import It_solutions from "./it-solutions/It_solutions";
import Smart_solutions from "./smart-solutions/Smart_solutions";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Banner />
      <We_are_avers />
      <Grow_Your_Business />
      <It_solutions />
      <Why_choose />
      <Smart_solutions />
      <How_we_work />
      <Want_discuss />
      <Tech_innovation />
      <Testimonials />
    </>
  );
}

export default Home;
