import React from "react";
import Service_development_Card from "../../../Common_Component/Service_development_Card/Service_development_Card";

const Service_development = () => {
  return (
    <>
      <section className="Service_development_sec">
        <div className="container ">
          <div className="bg-servicedev">
            <div className="ttilebg">
              <div className="row">
                <div className="col-md-6 mb-md-0 mb-3">
                  <p className="devtit">Digital Marketing</p>
                </div>
                <div className="col-md-6">
                  <p className="content">Unlock your brand’s potential with data-driven digital marketing strategies that increase visibility, engagement, and conversions.</p>
                </div>
              </div>
            </div>

            <div className="Service_development_Card_Holder">
              <div className="row">
                <div className="col-xl-10 col-md-11 col-11 mx-auto">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/Digital_Marketing_Services/search-engine-optimization.svg"} headingText={"Search Engine Optimization (SEO)"} descriptionText={"Boost your website's visibility on search engines with tailored SEO strategies that drive organic traffic and improve rankings."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 after_border_holder">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/Digital_Marketing_Services/Pay-Per-Click.svg"} headingText={"Pay-Per-Click (PPC) Advertising"} descriptionText={"Maximize ROI with targeted PPC campaigns that deliver immediate results. Drive traffic, leads, and conversions with precision."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/Digital_Marketing_Services/SocialMediaMarketing.svg"} headingText={"Social Media Marketing (SMM)"} descriptionText={"Engage your audience on platforms like Facebook, Instagram, and LinkedIn. Build brand awareness and foster meaningful connections."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/Digital_Marketing_Services/ContentMarketing.svg"} headingText={"Content Marketing"} descriptionText={"Attract and retain your audience with high-quality, engaging content that adds value and drives brand loyalty."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 after_border_holder">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/Digital_Marketing_Services/email-marketing.svg"} headingText={"Email Marketing"} descriptionText={"Stay connected with your audience through personalized email campaigns. Increase engagement, nurture leads, and boost sales."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/Digital_Marketing_Services/OnlineReputationManagement.svg"} headingText={"Online Reputation Management (ORM)"} descriptionText={"Safeguard your brand’s reputation online. Monitor, manage, and enhance your presence with effective ORM strategies."} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service_development;
