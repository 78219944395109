import React from "react";
import Service_development_Card from "../../../Common_Component/Service_development_Card/Service_development_Card";

const Service_development = () => {
  return (
    <>
      <section className="Service_development_sec">
        <div className="container ">
          <div className="bg-servicedev">
            <div className="ttilebg">
              <div className="row">
                <div className="col-md-6 mb-md-0 mb-3">
                  <p className="devtit">Brand Awarenesss</p>
                </div>
                <div className="col-md-6">
                  <p className="content"> Boost your brand recognition with creative campaigns that engage, inspire, and ensure your business stands out in a crowded market.</p>
                </div>
              </div>
            </div>

            <div className="Service_development_Card_Holder">
              <div className="row">
                <div className="col-xl-10 col-md-11 col-11 mx-auto">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                      <Service_development_Card  imgSrc={"/assets/images/it-solutions/Brand_Awareness/TargetedMarketingCampaigns.svg"} headingText={"Targeted Marketing Campaigns"}  descriptionText={"Reach the right audience through precise digital marketing and social media strategies."}/>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 after_border_holder">
                    <Service_development_Card  imgSrc={"/assets/images/it-solutions/Brand_Awareness/CreativeContent.svg"} headingText={"Creative Content"}  descriptionText={"Develop compelling content that resonates with your audience and showcases your brand’s value."}/>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                    <Service_development_Card  imgSrc={"/assets/images/it-solutions/Brand_Awareness/SocialMediaGrowth.svg"} headingText={"Social Media Growth"}  descriptionText={"Leverage social platforms to engage users and boost brand presence."}/>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                    <Service_development_Card  imgSrc={"/assets/images/it-solutions/Brand_Awareness/ConsistentMessaging.svg"} headingText={"Consistent Messaging"}  descriptionText={"Build a cohesive brand message that reinforces your identity across all channels."}/>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 after_border_holder">
                    <Service_development_Card  imgSrc={"/assets/images/it-solutions/Brand_Awareness/InfluencerCollaborations.svg"} headingText={"Influencer Collaborations"}  descriptionText={"Partner with influencers to enhance brand trust and credibility."}/>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                    <Service_development_Card  imgSrc={"/assets/images/it-solutions/Brand_Awareness/Analytics&Tracking.svg"} headingText={"Analytics & Tracking"}  descriptionText={"Measure campaign performance to refine strategies and ensure optimal results."}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service_development;
