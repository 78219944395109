import React from "react";
import "./Grow_Your_Business_content.css";
import Common_Button from "../../../Common_Component/Common_Button/Common_Button";
import { Link } from "react-router-dom";

function Grow_Your_Business_content() {
  return (
    <>
      <section className="Grow_Your_Business_content">
        <div className="content-holder">
          <div className="heading-holder">
            <h3>Grow Your Business with Profcyma</h3>
          </div>
          <div className="text-holder">
            <h6>
              Unlock your full potential with tailored solutions, cutting-edge
              technology, and expert support. Let us help you drive growth,
              efficiency, and success
            </h6>
            <p>
              At Profcyma, we specialize in delivering innovative, customized
              business solutions to help organizations thrive in a dynamic
              marketplace. With a focus on cutting-edge technology and strategic
              insights, we work closely with our clients to drive growth,
              optimize processes, and achieve sustainable success. Our team of
              experts is committed to providing tailored support and solutions
              that meet the unique needs of each business, empowering them to
              unlock their full potential.
            </p>
          </div>

          <Link to="/contact-us"><Common_Button btnText={"Contact Us"} /></Link>
        </div>
      </section>
    </>
  );
}

export default Grow_Your_Business_content;
