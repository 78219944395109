import React, { useState, useEffect } from 'react';
import './Gallary_sec.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const Gallary = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const images = [
        { src: '/assets/images/gallery/Events/image1.png', alt: 'Event Image 1' },
        { src: '/assets/images/gallery/Events/image2.png', alt: 'Event Image 2' },
        { src: '/assets/images/gallery/Events/image1.png', alt: 'Event Image 3' }
    ];

    const openModal = (index) => {
        setSelectedImage(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
    };

    const handlePrev = () => {
        setSelectedImage((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setSelectedImage((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <>
            <section className='Gallary_sec'>
                <div className='container'>
                    <div className='text-center'>
                        <h1 className='whotitle'>Gallery</h1>
                        <h5 className='secsub-title'>Captured Moments, Endless Stories</h5>
                    </div>

                    <div className='images-sec'>
                        <div className='row'>
                            {images.map((image, index) => (
                                <div key={index} className='col-md-4 col-6 mb-3'>
                                    <div className='img-div' onClick={() => openModal(index)}>
                                        <img
                                            className='gal-img'
                                            src={process.env.PUBLIC_URL + image.src}
                                            alt={image.alt}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            {isModalOpen && (
                <div className='modal-overlay' onClick={closeModal}>
                    <div className='modal-content' onClick={(e) => e.stopPropagation()}>
                        <button className='close-btn' onClick={closeModal}>✖</button>
                        <button className='prev-btn' onClick={handlePrev}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <img
                            className='modal-img'
                            src={process.env.PUBLIC_URL + images[selectedImage].src}
                            alt={images[selectedImage].alt}
                        />
                        <button className='next-btn' onClick={handleNext}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default Gallary;