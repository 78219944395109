import React from 'react'
import './Want_discuss.css'
import Common_Button from '../../Common_Component/Common_Button/Common_Button'
import { Link } from 'react-router-dom'

const Want_discuss = () => {
  return (
    <>
        <section className='Want_discuss_sec'>
            <div className='container'>
                <h1 className='wanttitle'><span>Want to Discuss</span> About A New Project?</h1>
                <div className='row justify-content-end mt-4'>
                    <div className='col-lg-5 col-md-7 text-end'>
                        <p className='wantp'>Let’s bring your ideas to life! Get in touch with us for tailored solutions and expert guidance to kickstart your project</p>
                        <Link to="/contact-us">
                        {/* <Common_Button btnText={"Contact Us"} /> */}
                        <button className='wantcont'>Contact Us</button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Want_discuss