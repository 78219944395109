import React, { useState, useEffect } from "react";
import "./App.css";
import "./index.css";

import { Route, Routes } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import Who_we_are from "./components/who-we-are/Who_we_are";
import Portfolio from "./components/portfolio/Portfolio";
import Blogs from "./components/blogs/Blogs";
import Gallary from "./components/gallary/Gallary";
import Blog_details from "./components/blog-details/Blog_details";
import Career from "./components/career/Career";
import Career_details from "./components/career-details/Career_details";
import Contact_us from "./components/contact-us/Contact_us";
import Website_developement from "./components/it-solutions/website-developement/Website_developement";
import Loader from "./components/loader/Loader";
import App_Development from "./components/it-solutions/App_Development/App_Development";
import Brand_Awarenesss from "./components/it-solutions/Brand_Awarenesss/Brand_Awarenesss";
import Digital_Marketing from "./components/it-solutions/Digital_Marketing/Digital_Marketing";
import E_Commerce_Development from "./components/it-solutions/E_Commerce_Development/E_Commerce_Development";
import Graphics_Designing from "./components/it-solutions/Graphics_Designing/Graphics_Designing";
import Software_Development from "./components/it-solutions/Software_Development/Software_Development";
import UI_UX_Design from "./components/it-solutions/UI_UX_Design/UI_UX_Design";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Set the timeout to 2 seconds

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Loader />; 
  }

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/who-we-are" element={<Who_we_are />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/gallery" element={<Gallary />} />
        <Route path="/blog-detail" element={<Blog_details />} />
        <Route path="/career" element={<Career />} />
        <Route path="/career-details" element={<Career_details />} />
        <Route path="/contact-us" element={<Contact_us />} />
        <Route path="/website-developement" element={<Website_developement />} />
        <Route path="/ui-ux-developement" element={<UI_UX_Design />} />
        <Route path="/software-developement" element={<Software_Development />} />
        <Route path="/graphics-developement" element={<Graphics_Designing />} />
        <Route path="/e-commerce-developement" element={<E_Commerce_Development />} />
        <Route path="/digital-developement" element={<Digital_Marketing />} />
        <Route path="/brand-developement" element={<Brand_Awarenesss />} />
        <Route path="/app-developement" element={<App_Development />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
