import React from 'react'
import './Our_team.css'

const Our_team = () => {

    const ourteam = [
        {
            name: "Guddu Kumar",
            position: "CTO/Project Manager",
            email: "guddu.kumar@profcyma.com",
            photo: "/assets/images/who-we-are/guddu.jpg",
            quote: "Success in technology is about aligning vision with action.",
            linkdin :"#"
        },
        {
            name: "Oshin Kawale",
            position: "Project Coordinator",
            email: "oshin.kawale@profcyma.com",
            photo: "/assets/images/who-we-are/oshin.jpg",
             quote: "The key to success is in the execution, not just the idea.",
            linkdin :"#"
        },
        {
            name: "Amarendra Kumar",
            position: "Technical Head / Server Administrator",
            email: "amar.thakur@profcyma.com",
            photo: "/assets/images/who-we-are/amar.jpeg",
            quote: "Technology may be complex, but its purpose is always simple: to improve lives.",
            linkdin :"#"
        },
        {
            name: "Siddhi Gupchup",
            position: "HR Manager",
            email: "hr@profcyma.com",
            photo: "/assets/images/who-we-are/hr1.jpg",
            quote: "At the heart of every successful organization is its people.",
            linkdin :"#"
        },
        {
            name: "Deepti Deshmukh",
            position: "HR Executive",
            email: "hr@profcyma.com",
            photo: "/assets/images/who-we-are/hr2.jpeg",
            quote: "We don’t just hire employees; we nurture leaders of tomorrow.",
            linkdin :"#"
        },
        {
            name: "Swapnil Patil",
            position: "Business Development Manager",
            email: "swapnil.patil@profcyma.com",
            photo: "/assets/images/who-we-are/swapnil.jpg",
            quote: "Dream big, act bigger—that’s how business grows.",
            linkdin :"#"
        }

    ];
    return (
        <>
            <section className='Our_team_sec'>
                <div className='container'>
                    <div className='text-center'>
                        <p className='sec-title'>Awesome team members</p>
                        <h5 className='secsub-title'>Our team</h5>
                    </div>

                    <div className='teamsec mt-5'>
                        <div className='row '>
                            <div className='col-md-10 mx-auto'>
                                <div className='row'>
                                    {/* {ourteam.map((data, index) => (
                                        <div className='col-md-4 mb-5'>
                                            <div className='team-main'>
                                                <div className='img-box'>
                                                    <img className='emp-img' src={process.env.PUBLIC_URL + data.photo } />
                                                </div>
                                                <div className='bgflip'></div>
                                                <div className='bg-overlay'></div>
                                                <p className='position'>{data.position}</p>
                                                <p className='name'>{data.name}</p>
                                                <p className='email'>{data.email}</p>
                                            </div>
                                          </div>
                                    ))} */}
                                    {ourteam.map((data, index) => (
                                        <div className='col-lg-4 col-sm-6 mb-5'>
                                            <div class="flip-card">
                                                <div class="flip-card-inner">
                                                    <div class="flip-card-front">
                                                        <img className='empimg' src={process.env.PUBLIC_URL + data.photo} alt="Avatar" />

                                                        <div className='black-overlay'></div>
                                                        <div className='overlaydata'>
                                                            <h1 className='empname'>{data.name}</h1>
                                                            <p className='epmpos'>{data.position}</p>
                                                            <p className='epmpos'>{data.email}</p>
                                                        </div>
                                                    </div>
                                                    <div class="flip-card-back">
                                                        <p className='quote'>"{data.quote}"</p>
                                                        <img className='empimg' src={process.env.PUBLIC_URL + data.photo} alt="Avatar" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Our_team