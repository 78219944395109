import React, { useEffect } from "react";
import Car_banner from './car-banner/Car_banner'
import Jobs from './job/Jobs'
import Doubt from "./doubt/Doubt";


const Career = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Car_banner />
            <Jobs />
            <Doubt />
        </>
    )
}

export default Career