import React from "react";
import Service_development_Card from "../../../Common_Component/Service_development_Card/Service_development_Card";

const Service_development = () => {
  return (
    <>
      <section className="Service_development_sec">
        <div className="container ">
          <div className="bg-servicedev">
            <div className="ttilebg">
              <div className="row">
                <div className="col-md-6 mb-md-0 mb-3">
                  <p className="devtit">E-commerce Development</p>
                </div>
                <div className="col-md-6">
                  <p className="content">We specialize in creating dynamic e-commerce solutions with features like multi-channel selling, payment gateway integration, and inventory management.
                  </p>
                </div>
              </div>
            </div>

            <div className="Service_development_Card_Holder">
              <div className="row">
                <div className="col-xl-10 col-md-11 col-11 mx-auto">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/website-devlopment/solutions/1.png"} headingText={"Custom E-Commerce Website Development"} descriptionText={"Create a personalized online store with custom features that match your brand and business goals. We design user-friendly, scalable, and secure e-commerce platforms."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 after_border_holder">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/website-devlopment/solutions/2.png"} headingText={"E-Commerce Platform Integration"} descriptionText={"Seamlessly integrate with leading e-commerce platforms like Shopify, WooCommerce, Magento, and BigCommerce to enhance functionality and streamline operations."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/website-devlopment/solutions/3.png"} headingText={"Payment Gateway Integration"} descriptionText={"Offer secure and smooth payment processing with integrated gateways. We support a variety of payment methods to provide a convenient experience for your customers."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/website-devlopment/solutions/4.png"} headingText={"Mobile E-Commerce Development"} descriptionText={"Ensure a flawless shopping experience on mobile devices. Our mobile-friendly e-commerce solutions drive sales and increase customer engagement on smartphones and tablets."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 after_border_holder">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/website-devlopment/solutions/5.png"} headingText={"Shopping Cart Development"} descriptionText={"Provide an intuitive and seamless shopping cart experience. We design easy-to-use carts that help customers make purchases quickly and efficiently."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/website-devlopment/solutions/6.png"} headingText={"E-Commerce SEO"} descriptionText={"Increase online visibility and drive traffic with tailored SEO strategies. We optimize product pages, metadata, and content to boost your rankings and sales."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/website-devlopment/solutions/6.png"} headingText={"E-Commerce Maintenance & Support"} descriptionText={"Ensure your e-commerce store runs smoothly with ongoing support and maintenance. We offer updates, security checks, and performance optimizations to keep your store at its best."} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service_development;
