import React from "react";
import Service_development_Card from "../../../Common_Component/Service_development_Card/Service_development_Card";

const Service_development = () => {
  return (
    <>
      <section className="Service_development_sec">
        <div className="container ">
          <div className="bg-servicedev">
            <div className="ttilebg">
              <div className="row">
                <div className="col-md-6 mb-md-0 mb-3">
                  <p className="devtit">App Development</p>
                </div>
                <div className="col-md-6">
                  <p className="content">From concept to launch, our team develops innovative apps that solve real problems and deliver real results.
                  </p>
                </div>
              </div>
            </div>

            <div className="Service_development_Card_Holder">
              <div className="row">
                <div className="col-xl-10 col-md-11 col-11 mx-auto">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/App_Development/AndroidAppDevelopment.svg"} headingText={"Android App Development"} descriptionText={"Unlock the potential of Android with custom app development. Our scalable, user-friendly apps deliver superior performance on all Android devices."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 after_border_holder">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/App_Development/iOSAppDevelopment.svg"} headingText={"iOS App Development"} descriptionText={"Build elegant and secure iOS apps tailored for Apple users. Our iOS solutions ensure seamless functionality and an exceptional user experience."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/App_Development/FlutterAppDevelopment.svg"} headingText={"Flutter App Development"} descriptionText={"Create high-performing apps with Flutter. Deliver fast, cross-platform experiences using a single codebase for Android, iOS, and more."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/App_Development/ReactNativeAppDevelopment.svg"} headingText={"React Native App Development"} descriptionText={"Leverage React Native for flexible, cross-platform apps. Achieve native-like performance with faster development and seamless integration."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 after_border_holder">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/App_Development/ProgressiveWebAppDevelopment.svg"} headingText={"Progressive Web App Development"} descriptionText={"Combine the best of web and mobile with progressive web apps. Fast, reliable, and engaging, they work smoothly across all platforms."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/App_Development/MVPDevelopment.svg"} headingText={"MVP Development"} descriptionText={"Validate your idea with a Minimum Viable Product. Get a functional app quickly, reduce risks, and gather user feedback for further enhancements."} />
                    </div>

                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service_development;
