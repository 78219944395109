import React from 'react'
import './Why_choose.css'

const Why_choose = () => {
    return (
        <>
            <section className='Why_choose_sec'>
                <div className='container'>
                    <div className='text-center'>
                        <p className='sec-title'>Why Choose Us</p>
                        <h5 className='secsub-title'>Fast & Easy Solutions</h5>
                    </div>

                    <div className='choosedata-sec'>
                        <div className='row'>
                            <div className='col-xl-3 col-lg-4 col-sm-6 mb-4'>
                                <div className='datadiv'>
                                    <h6>Tailored Solutions</h6>
                                    <p>Customized strategies to meet
                                        your unique business needs.</p>
                                </div>
                            </div>
                            <div className='col-xl-3 col-lg-4 col-sm-6 mb-4'>
                                <div className='datadiv'>
                                    <h6>Innovative Technology</h6>
                                    <p>Leveraging the latest tools for efficient and scalable results.</p>
                                </div>
                            </div>
                            <div className='col-xl-3 col-lg-4 col-sm-6 mb-4'>
                                <div className='datadiv'>
                                    <h6>Expert Team</h6>
                                    <p>Skilled professionals committed to your success.</p>
                                </div>
                            </div>
                            <div className='col-xl-3 col-lg-4 col-sm-6 mb-4'>
                                <div className='datadiv'>
                                    <h6>Customer-Centric Approach</h6>
                                    <p>We prioritize your goals and satisfaction.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Why_choose