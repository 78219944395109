import React from 'react'
import './Smart_solutions.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
const Smart_solutions = () => {
    return (
        <>
            <section className='Smart_solutions'>
                <div className='container'>
                    <div className='inner-div'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='img-box'>
                                    <div className='pic1box'>
                                        <img className='pic1' src={process.env.PUBLIC_URL + '/assets/images/home/smart-solutions/first.png'} />
                                    </div>

                                    <div className='pic2box'>
                                        <img className='pic2' src={process.env.PUBLIC_URL + '/assets/images/home/smart-solutions/second.png'} />
                                    </div>

                                    <img className='circleimg' src={process.env.PUBLIC_URL + process.env.PUBLIC_URL + '/assets/images/home/smart-solutions/circle.png'} />
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className=''>
                                    <p className='sec-title'>Driven by Smart Solutions</p>
                                    <h5 className='secsub-title ms-0'>Innovating Business Solutions</h5>
                                </div>

                                <div className='row mt-4'>
                                    <div className='col-md-6'>
                                        <div className='smlist'><p><FontAwesomeIcon className='me-1' icon={faAngleRight} />Technology Integration</p></div>
                                        <div className='smlist'><p><FontAwesomeIcon className='me-1' icon={faAngleRight} />Problem-Solving Approach</p></div>
                                        <div className='smlist'><p><FontAwesomeIcon className='me-1' icon={faAngleRight} />Scalable Growth</p></div>
                                        <div className='smlist'><p><FontAwesomeIcon className='me-1' icon={faAngleRight} />Adaptability and Agility</p></div>
                                        <div className='smlist'><p><FontAwesomeIcon className='me-1' icon={faAngleRight} />Customer-Centric Design</p></div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='smlist'><p><FontAwesomeIcon className='me-1' icon={faAngleRight} />Increased Efficiency</p></div>
                                        <div className='smlist'><p><FontAwesomeIcon className='me-1' icon={faAngleRight} />Enhanced Competitiveness</p></div>
                                        <div className='smlist'><p><FontAwesomeIcon className='me-1' icon={faAngleRight} />Better Decision-Making</p></div>
                                        <div className='smlist'><p><FontAwesomeIcon className='me-1' icon={faAngleRight} />Sustainable Growth</p></div>
                                        <div className='smlist'><p><FontAwesomeIcon className='me-1' icon={faAngleRight} />Improved Customer Engagement</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row justify-content-end'>
                            <div className='col-lg-7'>
                                <div className='counter-main'>
                                    <div className='row'>
                                        <div className='col-4 bd-right'>
                                            <div className='countdiv'>
                                                <h2 className='number'>500+</h2>
                                                <p className='name'>Projects Delivered</p>
                                            </div>
                                        </div>
                                        <div className='col-4 bd-right'>
                                            <div className='countdiv'>
                                                <h2 className='number'>10+</h2>
                                                <p className='name'>Technologies Used</p>
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='countdiv'>
                                                <h2 className='number'>400+</h2>
                                                <p className='name'>Trusted by companies</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Smart_solutions