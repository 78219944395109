import React from 'react'
import './founder.css'
import { Link } from 'react-router-dom'

const Founder = () => {
    return (
        <>
            <section className='Founder_sec'>
                <div className='container'>
                    <div className='bg-sec'>
                        <div className='row'>
                            <div className='col-lg-8'>
                                <div className='sec1'>
                                    <p className='founderp'>Founder & Managing Director</p>
                                    <p className='name'>Mr. Bharat Gaddamwar</p>
                                    <p className='content'>Meet Mr. Bharat Gaddamwar, the visionary founder &  Chief
                                        Executive Officer of Profcyma Global Solutions. A leader
                                        driven by passion and purpose, he has dedicated himself to
                                        transforming his goals into reality with unwavering
                                        determination.

                                        Under his dynamic leadership, Profcyma has  grown into a
                                        trusted name in the IT industry, known for  innovation,
                                        excellence, and client-focused solutions.</p>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='sec2'>
                                    <div className='whitebox'></div>
                                    <img className='bsimg' src={process.env.PUBLIC_URL + '/assets/images/who-we-are/bharatsir.png'} />

                                    <div className='socialiconsdiv'>
                                        <div className='imgbox'>
                                            <Link to="#">
                                                <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/who-we-are/twitter.png'} />    
                                            </Link>
                                        </div>
                                        <div className='imgbox'>
                                            <Link to="#">
                                                <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/who-we-are/linkedin.png'} />    
                                            </Link>
                                        </div>
                                        <div className='imgbox'>
                                            <Link to="#">
                                                <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/who-we-are/instagram.png'} />    
                                            </Link>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Founder