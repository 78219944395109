import React, { useEffect } from 'react'
import BlogsHeading from './Blogs-Heading/BlogsHeading'
import Categories from './Categories/Categories'
import TopPosts from './Top-Posts/TopPosts'
import SocialMedia from './Social-Media/SocialMedia'
import BlogsContent from './blogs-content/BlogsContent'

const Blogs = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <section className='Blogs_sec'>

            <BlogsHeading />

            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 col-md-6'>
                        <div className='left_section'>
                            <Categories />
                            <TopPosts />
                            <SocialMedia />
                        </div>
                    </div>
                    <div className='col-lg-8 col-md-6'>
                        <div className='right_section'>
                            <BlogsContent />
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Blogs
