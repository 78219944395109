import React from 'react'
import './How_we_work.css'

const How_we_work = () => {
    return (
        <>
            <section className='How_we_work_sec'>
                <div className='container'>
                    <div className='text-center'>
                        <p className='sec-title'>How we work</p>
                        <h5 className='secsub-title'>Simple Easy Steps to Follow</h5>
                    </div>

                    <div className='stepsec'>
                        <div className='row justify-content-between'>
                            <div className='col-md-5'>
                                <div className='leftside'>
                                    <div className='stepcard step1'>
                                        <h5>Understanding Your Vision</h5>
                                        <p>We begin by listening. Our team collaborates closely with you to understand your business goals, challenges, and vision. This helps us tailor solutions that truly meet your needs.</p>
                                    </div>
                                    <div className='stepcard step2'>
                                        <h5>Strategic Planning</h5>
                                        <p>Our experts analyze your requirements and develop a detailed plan. With a focus on innovation and efficiency, we craft strategies that align technology with your business objectives.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-2 col-4 my-auto p-0 mx-auto'>
                                <div className='circle-div'>
                                    <img className='circle' src={process.env.PUBLIC_URL + '/assets/images/logo/mask.png'} />
                                </div>
                            </div>

                            <div className='col-md-5'>
                                <div className='rightside '>
                                    <div className='stepcard step2'>
                                        <h5>Quality Assurance</h5>
                                        <p>Our process is iterative and transparent. By following agile methodologies, we adapt quickly to your feedback, ensuring timely delivery and minimizing risks.</p>
                                    </div>
                                    <div className='stepcard step1'>
                                        <h5>Support & Optimization</h5>
                                        <p>Our partnership doesn’t end at delivery. We provide continuous support, monitor performance, and optimize solutions to keep your business ahead of the curve.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default How_we_work