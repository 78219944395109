import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import SuccessLottey from './SuccessLottey/SuccessLottey';

const Successful_modal = (props) => {
    useEffect(() => {
        if (props.show) {
            const timer = setTimeout(() => {
                props.onHide(); // Call the onHide function after 3 seconds
            }, 3000);
            return () => clearTimeout(timer); // Cleanup timer if the component unmounts or props.show changes
        }
    }, [props.show]);

    return (
        <>
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='succfullmodal'
            >
                <Modal.Body>
                    <div className='d-flex'>
                        <div className='lottidiv'>
                            <SuccessLottey />
                        </div>
                        <div className='my-auto'>
                            <p className='messagep'>{props.message}</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Successful_modal;