import React, { useEffect, useState } from "react";
import Service_breadcrumb from "./service-breadcrumb/Service_breadcrumb";
import Service_title from "./service-title/Service_title";
import Service_development from "./service-developement/Service_development";
import Development_Process from "./Development_Process/Development_Process";
import Get_in_Touch from "./Get_in_Touch/Get_in_Touch";

const Brand_Awarenesss = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Service_breadcrumb />
      <Service_title />
      <Service_development />
      <Development_Process />
      <Get_in_Touch/>
    </>
  );
};

export default Brand_Awarenesss;
