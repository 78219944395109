import React from 'react'
import './BlogsHeading.css'

const BlogsHeading = () => {
    return (
        <section className='BlogsHeading'>
            <div className='container'>
                <div className='titlediv'>
                    <div className='text-center'>
                        <h1 className='whotitle'>Blogs</h1>
                        <h5 className='secsub-title'>The Power of Digital Transformation in Modern Enterprises</h5>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogsHeading
