import React from "react";
import Development_Process_Title from "./Development_Process_Title/Development_Process_Title";
import Development_Process_Tab from "./Development_Process_Tab/Development_Process_Tab";

function Development_Process() {
  return (
    <>
      <section className="Development_Process">
        <div className="container">
          <div className="Web_Development_Process_holder">
            <Development_Process_Title />
            <Development_Process_Tab />
          </div>
        </div>
      </section>
    </>
  );
}

export default Development_Process;
