import React, { useEffect, useState } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import '../../../header/Header.css';
import { Link, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faChevronDown,
    faMagnifyingGlass,
    faShoppingCart,
    faUser,
} from "@fortawesome/free-solid-svg-icons";

const It_solution_offcanvas = ({ showOffcanvas, handleClose }) => {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState("home");
  
    useEffect(() => {
        const path = location.pathname.substring(1);
        setActiveLink(path || "home");
    }, [location.pathname]);
  
    const handleNavItemClick = (selectedLink) => {
        setActiveLink(selectedLink);
        const navbarToggle = document.querySelector(".navbar-toggler");
        if (navbarToggle && window.innerWidth <= 992) {
            navbarToggle.click();
        }
    };

    const itsolution = [
        {
            name: 'Website Development',
            image: '/assets/images/header/it-solutions/website.png',
            url: '/website-developement',
        },
        {
            name: 'App Development',
            image: '/assets/images/header/it-solutions/app.png',
            url: '/app-developement',
        },
        {
            name: 'Digital Marketing',
            image: '/assets/images/header/it-solutions/digital.png',
            url: '/digital-developement',
        },
        {
            name: 'UI/UX Design',
            image: '/assets/images/header/it-solutions/ui-ux.png',
            url: '/ui-ux-developement',
        },
        {
            name: 'Software Development',
            image: '/assets/images/header/it-solutions/website.png',
            url: '/software-developement',
        },
        {
            name: 'Brand Awarenesss',
            image: '/assets/images/header/it-solutions/brand.png',
            url: '/brand-developement',
        },
        {
            name: 'Graphics Designing',
            image: '/assets/images/header/it-solutions/graphics.png',
            url: '/graphics-developement',
        },
        {
            name: 'E-commerce Development',
            image: '/assets/images/header/it-solutions/ecom.png',
            url: '/e-commerce-developement',
        },
    ];

    return (
        <>
            {['top'].map((placement, idx) => (
                <Offcanvas
                    className="itsoldropoffcanvas"
                    key={idx}
                    placement={placement}
                    show={showOffcanvas}
                    onHide={handleClose}
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="container">
                            <div className="row">
                                {itsolution?.map((data, index) => (
                                    <div className="col-lg-4 col-6 mb-3" key={index}>
                                        <Link to={data.url} onClick={() => handleNavItemClick(data.name)}>
                                            <div className="solcard" onClick={handleClose}>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="imgdiv">
                                                            <img className="solimg" src={process.env.PUBLIC_URL + data?.image} alt={data.name} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="namediv">
                                                            <h6 className="name">{data?.name}</h6>
                                                            <p className="exsol">
                                                                Explore Solution <FontAwesomeIcon icon={faArrowRight} />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            ))}
        </>
    );
};

export default It_solution_offcanvas;
