import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './Categories.css'
import { Link } from 'react-router-dom'


const Categories = () => {
    return (
        <section className='Categories'>
            <div className='input_div'>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Search..." aria-label="Recipient's username" aria-describedby="basic-addon2" />
                        <div class="input-group-append icon_main_holder">
                            <span class="input-group-text icon_span" id="basic-addon2">
                            <FontAwesomeIcon icon={faMagnifyingGlass} className='icon'/>
                            </span>
                        </div>
                </div>
            </div>

            <div className='black_card_holder'>
                <div className='card_heading'>
                    <p>Categories</p>
                </div>
                <div className='content_holder'>
                    <div className='inner_content_holder'>
                        <div>
                            <Link to="#">Technology Trends and Innovations</Link>
                        </div>
                        <div>
                            <Link to="#">Technology Trends and Innovations</Link>
                        </div>
                        <div>
                            <Link to="#">Technology Trends and Innovations</Link>
                        </div>
                        <div>
                            <Link to="#">Technology Trends and Innovations</Link>
                        </div>
                        <div>
                            <Link to="#">Technology Trends and Innovations</Link>
                        </div>
                        <div>
                            <Link to="#">Technology Trends and Innovations</Link>
                        </div>
                        <div>
                            <Link to="#">Technology Trends and Innovations</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Categories
