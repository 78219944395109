import React from 'react'
import './Who_we.css'

const Who_we = () => {
  return (
    <>
        <section className='Who_we_sec'> 
            <div className='container'>
                <div className='titlediv'>
                    <div className='text-center'>
                        <h1 className='whotitle'>Who we are</h1>
                        <h5 className='secsub-title'>Creating and working together for improvement</h5>
                    </div>

                    <img className='whoban' src={process.env.PUBLIC_URL + '/assets/images/who-we-are/banner.png'} />

                    <p className='bigp'>Profcyma Global Solutions: Transforming IT with Cutting-Edge Technologies</p>
                    <p className='contentt'>Profcyma Global Solutions, a leading IT company in Pune, is revolutionizing the industry with innovative next-gen technologies. Backed by over 8+ years of expertise, our dedicated team of skilled professionals delivers exceptional IT solutions tailored to global clients' needs. At the heart of our success lies an unwavering commitment to client satisfaction, driving us to always achieve outstanding results. Partner with us to experience excellence in IT services that redefine success.</p>
                </div>
            </div>
        </section>
    </>
  )
}

export default Who_we