import React from 'react'
import './Car_details.css'
import Job_apply_form from '../job-apply-form/Job_apply_form'
const Car_details = () => {
    return (
        <>
            <section className='Car_details'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='sec1'>
                                <div className='main-roles'>
                                    <div className='row'>
                                        <div className='col-lg-10 mx-auto'>
                                            <div className='row mainjbbg'>
                                                <div className='col-4'>
                                                    <p className='mainjobrol'>Job Role:</p>
                                                </div>
                                                <div className='col-8'>
                                                    <p className='mainjobrol'>Node.js Developer</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <p className='mainjobrol'>Job Department:</p>
                                                </div>
                                                <div className='col-8'>
                                                    <p className='mainjobrol'>Backend developer</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <p className='mainjobrol'>Job Location:</p>
                                                </div>
                                                <div className='col-8'>
                                                    <p className='mainjobrol'>Pune</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <p className='mainjobrol'>Job Type:</p>
                                                </div>
                                                <div className='col-8'>
                                                    <p className='mainjobrol'>Full Time</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-lg-10 mx-auto'>
                                        <p className='mainjobrol'>Job Details:</p>
                                        <p className='jobdesc'>We are looking for a highly capable Node.js developer to optimize our web-based application performance. You will be collaborating with our front-end application developers, designing back-end components, and integrating data storage and protection solutions.</p>

                                        <p className='jobdesc undrl'>Responsibilities include:</p>
                                        <ul>
                                            <li className='jobdesc'>Must have work experience with Flutter SDK and Dart programming language.
                                            </li>
                                            <li className='jobdesc'> Experience with third-party libraries and APIs.
                                            </li>
                                            <li className='jobdesc'>Experience working with Firebase database
                                            </li>
                                            <li className='jobdesc'>Strong knowledge on Flutter widgets for both Android/iOS
                                            </li>
                                            <li className='jobdesc'> Good knowledge in OOPS concepts
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5'>
                            <Job_apply_form />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Car_details