import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import "./Development_Process_Tab.css";
import { Nav, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Development_Process_Tab_Content_Card from "../../../../Common_Component/Development_Process_Tab_Content_Card/Development_Process_Tab_Content_Card";

const tabsData = [
  {
    key: "first",
    title: "Planning & Roadmap",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/1.png",
    numberText: "1",
    processName: "Planning & Roadmap",
    listContent: (
      <>
        <ul>
          <li>
            - Identify client goals, target audience, and website objectives
          </li>
          <li>
            - Formulate a strategic plan aligning with business needs and market
            trends.
          </li>
        </ul>
      </>
    ),
  },
  {
    key: "second",
    title: "Analysis & Planning",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/2.png",
    numberText: "2",
    processName: "Analysis & Planning",
    listContent: (
      <>
        <ul>
          <li>
            - Conduct a thorough analysis of project requirements and
            constraints
          </li>
          <li>
            - Develop a detailed project plan, defining roles, responsibilities,
            & timelines
          </li>
        </ul>
      </>
    ),
  },
  {
    key: "third",
    title: "UI/UX Design",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/3.png",
    numberText: "3",
    processName: "UI/UX Design",
    listContent: (
      <>
        <ul>
          <li>
            - Create wireframes and visual mockups to define the website’s user
            interface
          </li>
          <li>
            - Ensure a seamless user experience by focusing on navigation and
            aesthetics
          </li>
        </ul>
      </>
    ),
  },
  {
    key: "fourth",
    title: "Web Development",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/4.png",
    numberText: "4",
    processName: "Web Development",
    listContent: (
      <>
        <ul>
          <li>
            - Write code based on design specifications, implementing
            functionality
          </li>
          <li>
            - Utilize best practices in coding to ensure responsiveness and
            cross-browser compatibility
          </li>
        </ul>
      </>
    ),
  },
  {
    key: "fifth",
    title: "Quality Assurance & Testing",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/5.png",
    numberText: "5",
    processName: "Quality Assurance & Testing",
    listContent: (
      <>
        <ul>
          <li>
            - Conduct comprehensive testing for functionality, usability, and
            performance
          </li>
          <li>
            - Identify and resolve any bugs or issues to ensure a flawless user
            experience
          </li>
        </ul>
      </>
    ),
  },
  {
    key: "sixth",
    title: "Deployment & Support",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/6.png",
    numberText: "6",
    processName: "Deployment & Support",
    listContent: (
      <>
        <ul>
          <li>
            - Launch the website on the specified cloud or server according to
            requirements
          </li>
          <li>- Preparing Metadata</li>
          <li>- Submitting the Code to Stores</li>
          <li>- Ongoing Support and Performance Monitoring</li>
        </ul>
      </>
    ),
  },
];

function Development_Process_Tab() {
  return (
    <section className="Development_Process_Tab">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className="row me-0 ms-0">
          <div className="col-lg-5 desktop-view-tab-holder">
            <Nav variant="pills" className="flex-column">
              {tabsData.map(({ key, title }) => (
                <Nav.Item key={key}>
                  <Nav.Link eventKey={key}>
                    <div className="tab-name-holder">                      
                      <p>{title}</p>
                      <div className="arrow-holder">
                        <FontAwesomeIcon
                          className="arrow-icon"
                          icon={faAngleRight}
                        />
                      </div>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>

          <div className="col-md-12 mobile-view-tab-holder">
            <Nav variant="pills" className="tabs-nav">
              <Swiper
                modules={[Navigation]}
                spaceBetween={10}
                slidesPerView={3} // Adjust based on how many tabs should be visible
                navigation
                pagination={{ clickable: true }}
                className="tabs-swiper"
                breakpoints={{
                  0: {
                    // For small screens
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },

                  486: {
                    // For small screens
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },

                  575: {
                    // For small screens
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },
                  768: {
                    // For medium screens
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                }}
              >
                {tabsData.map(({ key, title }) => (
                  <SwiperSlide key={key}>
                    <Nav.Item>
                      <Nav.Link eventKey={key}>
                        <div className="tab-name-holder">
                          <p>{title}</p>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Nav>
          </div>

          <div className="col-lg-7 col-md-12 tab-content-holder">
            <Tab.Content>
              {tabsData.map(
                ({ key, imgSrc, numberText, processName, listContent }) => (
                  <Tab.Pane eventKey={key} key={key}>
                    <Development_Process_Tab_Content_Card
                      imgSrc={imgSrc}
                      numberText={numberText}
                      proccesName={processName}
                      listContent={listContent}
                    />
                  </Tab.Pane>
                )
              )}
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </section>
  );
}

export default Development_Process_Tab;
