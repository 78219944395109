import React from 'react'
import './TopPosts.css'
import { Link } from 'react-router-dom'

const TopPosts = () => {
    return (
        <section className='TopPosts'>

            <div className='black_card_holder'>
                <div className='card_heading'>
                    <p>Top Posts</p>
                </div>
                <div className='content_holder'>
                    <div className='post_content_holder'>
                        <div className='inner_div'>
                            <div className='row'>
                                <div className='col-lg-2 col-md-2 col-sm-2 col-2'>
                                    <div className='number_div'>
                                        <p>01</p>
                                    </div>
                                </div>

                                <div className='col-lg-10 col-md-10 col-sm-10 col-10'>
                                    <div className='text_holder'>
                                        <p>Streamlining Processes with IT Automation Solutions</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='inner_div'>
                            <div className='row'>
                                <div className='col-lg-2 col-md-2 col-sm-2 col-2'>
                                    <div className='number_div'>
                                        <p>02</p>
                                    </div>
                                </div>

                                <div className='col-lg-10 col-md-10 col-sm-10 col-10'>
                                    <div className='text_holder'>
                                        <p>Cybersecurity Essentials: Protecting Your Business in the Digital Age</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='inner_div'>
                            <div className='row'>
                                <div className='col-lg-2 col-md-2 col-sm-2 col-2'>
                                    <div className='number_div'>
                                        <p>03</p>
                                    </div>
                                </div>

                                <div className='col-lg-10 col-md-10 col-sm-10 col-10'>
                                    <div className='text_holder'>
                                        <p>Streamlining Processes with IT Automation Solutions</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='inner_div'>
                            <div className='row'>
                                <div className='col-lg-2 col-md-2 col-sm-2 col-2'>
                                    <div className='number_div'>
                                        <p>04</p>
                                    </div>
                                </div>

                                <div className='col-lg-10 col-md-10 col-sm-10 col-10'>
                                    <div className='text_holder'>
                                        <p>Streamlining Processes with IT Automation Solutions</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TopPosts
