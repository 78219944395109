import React from 'react'
import Form from 'react-bootstrap/Form';
import './contact_form.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Successful_modal from '../../Common_Component/modals/succcessful-modal/Successful_modal';

const Contact_form = () => {

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <section className='Contact_form'>        
        <Form>
          <div className='row'>
            <div className='col-lg-6 mb-4'>
              <div className='form-group'>
                <Form.Label>First Name*</Form.Label>
                <Form.Control type="text" placeholder="First your First Name" />
              </div>
            </div>
            <div className='col-lg-6 mb-4'>
              <div className='form-group'>
                <Form.Label>Last Name*</Form.Label>
                <Form.Control type="text" placeholder="First your Last Name" />
              </div>
            </div>
            <div className='col-lg-6 mb-4'>
              <div className='form-group'>
                <Form.Label>Email*</Form.Label>
                <Form.Control type="text" placeholder="First your Email" />
              </div>
            </div>
            <div className='col-lg-6 mb-4'>
              <div className='form-group'>
                <Form.Label>Contact Number*</Form.Label>
                <Form.Control type="text" placeholder="First your Contact Number" />
              </div>
            </div>
            <div className='col-lg-12 mb-4'>
              <div className='form-group'>
                <Form.Label>Industry*</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Select your Industry</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
                <button className='srcbtn' type='button'><FontAwesomeIcon className='srchicn' icon={faSortDown} /></button>
              </div>
            </div>

            <div className='col-lg-12 mb-4'>
              <div className='form-group'>
                <Form.Label>How we can help you *</Form.Label>
                <Form.Control style={{ height: '100px' }} as="textarea" placeholder="Type From here..." />
              </div>
            </div>
            <div className='col-lg-12 mb-4'>
              <div className='form-group'>
                <button className='submit-btn'type="button" onClick={() => setModalShow(true)}>Submit</button>
              </div>
            </div>
          </div>
        </Form>
      </section>


      <Successful_modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        message = "Message Sent Successfully"
      />
    </>
  )
}

export default Contact_form