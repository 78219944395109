import React from "react";
import Service_development_Card from "../../../Common_Component/Service_development_Card/Service_development_Card";

const Service_development = () => {
  return (
    <>
      <section className="Service_development_sec">
        <div className="container ">
          <div className="bg-servicedev">
            <div className="ttilebg">
              <div className="row">
                <div className="col-md-6 mb-md-0 mb-3">
                  <p className="devtit">Graphics Designing</p>
                </div>
                <div className="col-md-6">
                  <p className="content">From logos to marketing materials, our graphic design services bring your ideas to life with creativity, precision, and style.</p>
                </div>
              </div>
            </div>

            <div className="Service_development_Card_Holder">
              <div className="row">
                <div className="col-xl-10 col-md-11 col-11 mx-auto">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                      <Service_development_Card  imgSrc={"/assets/images/it-solutions/Graphic_Designing/logo-design.svg"} headingText={"Logo Design"}  descriptionText={"Create a unique, memorable logo that captures your brand’s essence. Our logo designs are simple, impactful, and tailored to leave a lasting impression."}/>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 after_border_holder">
                    <Service_development_Card  imgSrc={"/assets/images/it-solutions/Graphic_Designing/BrandIdentityDesign.svg"} headingText={"Brand Identity Design"}  descriptionText={"Establish a cohesive brand identity with custom design elements that communicate your brand’s values and resonate with your target audience."}/>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                    <Service_development_Card  imgSrc={"/assets/images/it-solutions/Graphic_Designing/PrintDesign.svg"} headingText={"Print Design"}  descriptionText={"From brochures and flyers to business cards and posters, we design print materials that capture attention, enhance your brand, and drive engagement."}/>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                    <Service_development_Card  imgSrc={"/assets/images/it-solutions/Graphic_Designing/SocialMediaGraphics.svg"} headingText={"Social Media Graphics"}  descriptionText={"Elevate your social media presence with eye-catching graphics designed to engage your audience, increase brand visibility, and encourage interaction."}/>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 after_border_holder">
                    <Service_development_Card  imgSrc={"/assets/images/it-solutions/Graphic_Designing/ui&web-design.svg"} headingText={"Web & UI Design"}  descriptionText={"Create visually appealing, user-friendly websites and interfaces. Our designs are both aesthetic and functional, improving the user experience and interaction."}/>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                    <Service_development_Card  imgSrc={"/assets/images/it-solutions/Graphic_Designing/PackagingDesign.svg"} headingText={"Packaging Design"}  descriptionText={"Design attractive, functional packaging that stands out on the shelf and communicates your product’s unique value, all while staying true to your brand’s image."}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service_development;
