import React from "react";
import "./Grow_Your_Business.css";
import Grow_Your_Business_content from "./Grow_Your_Business_content/Grow_Your_Business_content";
import VisionMission from "./VisionMission/VisionMission";

function Grow_Your_Business() {
  return (
    <>
      <section className="Grow_Your_Business">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <Grow_Your_Business_content />
            </div>

            <div className="col-lg-6">
              <VisionMission />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Grow_Your_Business;
