import React from "react";
import Contact_form from "../../../contact-us/contact-form/Contact_form";

function Get_in_Touch() {
  return (
    <>
      <section className="Get_in_Touch">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-11">
              <div className="Get_in_Touch_holder">
                <div className="row justify-content-between">
                  <div className="col-md-6 col-12">
                    <div className="text-holder">
                      <h2>
                        Get in Touch to Transform  <br />Your Ideas into Reality!
                      </h2>

                      <p>
                        Do you have a project in mind? <br /> - Talk to the Experts.
                      </p>
                    </div>

                    <div className="img-holder">
                      <img
                        className="contact_img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/it-solutions/website-devlopment/Get_in_Touch/contact-img.png"
                        }
                        alt="contact_img"
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-12">
                    <Contact_form />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Get_in_Touch;
