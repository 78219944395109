import React, { useEffect } from 'react'
import Who_we from './who-we/Who_we'
import Our_team from './our-team/Our_team'
import Founder from './founder/Founder'

const Who_we_are = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Who_we />
            <Founder />
            <Our_team />
        </>
    )
}

export default Who_we_are