import React, { useState ,useEffect } from 'react'
import './contact-us.css'
import Contact_form from './contact-form/Contact_form'

const Contact_us = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <section className='Contact_us_sec'>
                
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 px-0'>
                            <div className='contaddbg'>
                                <p className='gettoch'>Get in Touch with Us</p>
                                <h5 class="secsub-title ms-0">Contact Us</h5>
                                <div className='contdetails'>
                                    <div className='addflex'>
                                        <div className='icondiv'>
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/contact-us/location.png'} />
                                        </div>
                                        <div className='infodiv'>
                                            <p className='text bd-bot'>2nd floor, Office No 3, Opp high-class soc, M.no 734 Near Suryadutta College,
                                                Bavdhan Khurd, Pune 411021.</p>
                                        </div>
                                    </div>
                                    <div className='addflex'>
                                        <div className='icondiv'>
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/contact-us/mail.png'} />
                                        </div>
                                        <div className='infodiv my-auto'>
                                            <p className='text bd-bot'>info@profcymaglobal.com</p>
                                        </div>
                                    </div>
                                    <div className='addflex'>
                                        <div className='icondiv'>
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/contact-us/call.png'} />
                                        </div>
                                        <div className='infodiv my-auto'>
                                            <p className='text'>+91 960 775 5557 | 940 570 90425</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 px-0'>
                            <Contact_form />
                        </div>
                    </div>
                </div>

                <div className='map-section mt-5'>
                    <div className="gmap_canvas">
                        <iframe
                            className="gmap_iframe"
                            frameborder="0"
                            scrolling="no"
                            marginheight="0"
                            marginwidth="0"
                            src="https://maps.google.com/maps?width=600&amp;height=800&amp;hl=en&amp;q=pune&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        ></iframe>
                        {/* <a
                            href="https://pdflist.com/"
                            alt="pdflist.com"
                        ></a> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact_us