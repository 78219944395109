import React from 'react'
import './Blog_details_banner.css'

const Blog_details_banner = () => {
  return (
    <>
      <section className='Blog_details_banner'>
        <div className='container'>
          <div className='img-div'>
            <img className='banimg' src={process.env.PUBLIC_URL + '/assets/images/blogs/blogs/banner.png'} />
            <div className='overlay-color'></div>

            <div className='row'>
              <div className='col-lg-11 mx-auto'>
                <p className='blog-name'>Top 5 Cybersecurity Tips to Protect Your Business in the Digital Age</p>
                <p className='blog-date'>-22 Nov 2024</p>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default Blog_details_banner