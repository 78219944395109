import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Nav, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Development_Process_Tab_Content_Card from "../../../../Common_Component/Development_Process_Tab_Content_Card/Development_Process_Tab_Content_Card";

const tabsData = [
  {
    key: "first",
    title: "Discovery & Concept Development",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/1.png",
    numberText: "1",
    processName: "Discovery & Concept Development",
    listContent: (
      <>
        <ul>
          <li>
            - We start by understanding your brand, goals, and vision, conducting research to craft unique design concepts that resonate with your target audience.
          </li>
          
        </ul>
      </>
    ),
  },
  {
    key: "second",
    title: "Design Creation & Refinement",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/2.png",
    numberText: "2",
    processName: "Design Creation & Refinement",
    listContent: (
      <>
        <ul>
          <li>
            - Our designers create visually compelling and effective designs, iterating based on feedback to ensure the final product aligns with your brand identity and objectives.
          </li>          
        </ul>
      </>
    ),
  },
  {
    key: "third",
    title: "Review & Feedback",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/3.png",
    numberText: "3",
    processName: "Review & Feedback",
    listContent: (
      <>
        <ul>
          <li>
            - We collaborate with you to review designs, refine details, and make adjustments to ensure everything is perfect before moving to the next stage.
          </li>
          
        </ul>
      </>
    ),
  },
  {
    key: "fourth",
    title: "Finalization & Delivery",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/4.png",
    numberText: "4",
    processName: "Finalization & Delivery",
    listContent: (
      <>
        <ul>
          <li>
            - Once approved, we prepare all assets in the necessary formats, providing you with the final design files and guidelines for seamless implementation across all channels.
          </li>          
        </ul>
      </>
    ),
  },
  
];

function Development_Process_Tab() {
  return (
    <section className="Development_Process_Tab">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className="row me-0 ms-0">
          <div className="col-lg-5 desktop-view-tab-holder">
            <Nav variant="pills" className="flex-column">
              {tabsData.map(({ key, title }) => (
                <Nav.Item key={key}>
                  <Nav.Link eventKey={key}>
                    <div className="tab-name-holder">
                      <p>{title}</p>
                      <div className="arrow-holder">
                        <FontAwesomeIcon
                          className="arrow-icon"
                          icon={faAngleRight}
                        />
                      </div>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>

          <div className="col-md-12 mobile-view-tab-holder">
            <Nav variant="pills" className="tabs-nav">
              <Swiper
                modules={[Navigation]}
                spaceBetween={10}
                slidesPerView={3} // Adjust based on how many tabs should be visible
                navigation
                pagination={{ clickable: true }}
                className="tabs-swiper"
                breakpoints={{
                  0: {
                    // For small screens
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },

                  486: {
                    // For small screens
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },

                  575: {
                    // For small screens
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },
                  768: {
                    // For medium screens
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                }}
              >
                {tabsData.map(({ key, title }) => (
                  <SwiperSlide key={key}>
                    <Nav.Item>
                      <Nav.Link eventKey={key}>
                        <div className="tab-name-holder">
                          <p>{title}</p>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Nav>
          </div>

          <div className="col-lg-7 col-md-12 tab-content-holder">
            <Tab.Content>
              {tabsData.map(
                ({ key, imgSrc, numberText, processName, listContent }) => (
                  <Tab.Pane eventKey={key} key={key}>
                    <Development_Process_Tab_Content_Card
                      imgSrc={imgSrc}
                      numberText={numberText}
                      proccesName={processName}
                      listContent={listContent}
                    />
                  </Tab.Pane>
                )
              )}
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </section>
  );
}

export default Development_Process_Tab;
