import React from 'react'
import "./Common_Button.css"
import { Button } from 'react-bootstrap'

function Common_Button({btnText}) {
  return (
   <>
    <section className='Common_Button_holder'>
        <Button className="Common_Button knmor">
            {btnText}
        </Button>
    </section>
   </>
  )
}

export default Common_Button