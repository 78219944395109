import React from "react";
import "./Service_development.css";
import Service_development_Card from "../../../Common_Component/Service_development_Card/Service_development_Card";

const Service_development = () => {
  return (
    <>
      <section className="Service_development_sec">
        <div className="container ">
          <div className="bg-servicedev">
            <div className="ttilebg">
              <div className="row">
                <div className="col-md-6 mb-md-0 mb-3">
                  <p className="devtit">Website Development</p>
                </div>
                <div className="col-md-6">
                  <p className="content">
                  Our skilled team crafts seamless, responsive websites that not only look stunning but deliver measurable results for your business.
                  </p>
                </div>
              </div>
            </div>

            <div className="Service_development_Card_Holder">
              <div className="row">
                <div className="col-xl-10 col-md-11 col-11 mx-auto">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                      <Service_development_Card  imgSrc={"/assets/images/it-solutions/website-devlopment/solutions/1.png"} headingText={"Single Page Website Development"}  descriptionText={"Create impact with a sleek, fast-loading single-page website. Ideal for portfolios and promotions, it offers seamless navigation and stunning design."}/>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 after_border_holder">
                    <Service_development_Card  imgSrc={"/assets/images/it-solutions/website-devlopment/solutions/2.png"} headingText={"Responsive Website Design"}  descriptionText={"Ensure your site looks perfect on all devices. Our responsive designs adapt flawlessly to every screen size, enhancing user experience and engagement."}/>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                    <Service_development_Card  imgSrc={"/assets/images/it-solutions/website-devlopment/solutions/3.png"} headingText={"Custom Web App Development"}  descriptionText={"Get custom web apps tailored to your needs. Scalable, secure, and user-friendly, our solutions drive efficiency and boost digital experiences."}/>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                    <Service_development_Card  imgSrc={"/assets/images/it-solutions/website-devlopment/solutions/4.png"} headingText={"CMS Website Development"}  descriptionText={"Take control with CMS websites. Easily update content on platforms like WordPress, offering flexibility, scalability, and search engine optimization."}/>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 after_border_holder">
                    <Service_development_Card  imgSrc={"/assets/images/it-solutions/website-devlopment/solutions/5.png"} headingText={"E-Commerce Website Development"}  descriptionText={"Boost sales with an intuitive e-commerce site. Mobile-friendly designs, secure payment gateways, and optimized navigation ensure a seamless shopping experience."}/>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                    <Service_development_Card  imgSrc={"/assets/images/it-solutions/website-devlopment/solutions/6.png"} headingText={"Web Hosting and Maintenance"}  descriptionText={"Reliable hosting with 99.9% uptime. Maintenance includes updates, security checks, and performance optimization for a flawless website experience."}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service_development;
