import React from "react";
import { Container, Row } from "react-bootstrap";
import "./VisionMission.css"; // Custom CSS for styling
import LottiePlayerSection from "../../../Common_Component/LottiePlayerSection/LottiePlayerSection";
import LottiePlayerSection2 from "../../../Common_Component/LottiePlayerSection/LottiePlayerSection2";

const VisionMission = () => {
  return (
    <Container className="vision_mission_container">
      <div className="VisionMission">
        <Row className="segment-row">
          {/* Top Left Segment */}
          <div className="col-6 segment top-left odd-segment">
            <div>
              <div className="icon mb-2">
                <LottiePlayerSection2 />
              </div>
            </div>
          </div>

          {/* Top Right Segment */}
          <div className="col-6 segment top-right even-segment">
            <div>
              <h2>Vision:</h2>
              <p>
                To be a global leader in delivering innovative & transformative
                business solutions
              </p>
            </div>
          </div>

          {/* Bottom Left Segment */}
          <div className="col-6 segment bottom-left even-segment">
            <div>
              <h2>Mission:</h2>
              <p>
                We are empowering businesses with tailored strategies &
                cutting-edge technology for sustainable growth
              </p>
            </div>
          </div>

          {/* Bottom Right Segment */}
          <div className="col-6 segment bottom-right odd-segment">
            <div>
              <div className="icon mb-2">
                <LottiePlayerSection />
              </div>
            </div>
          </div>
        </Row>
      </div>
    </Container>
  );
};

export default VisionMission;
