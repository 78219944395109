import React, { useState, useEffect } from "react";
import './Loader.css'



const Loader = () => {    
    return (
        <>
            <div className="loader-container">
                <img src={process.env.PUBLIC_URL + '/assets/images/loader/loder.gif'} alt="Loading..." className="loader-gif" />
            </div>
        </>
    )
}

export default Loader