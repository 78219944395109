import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Nav, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Development_Process_Tab_Content_Card from "../../../../Common_Component/Development_Process_Tab_Content_Card/Development_Process_Tab_Content_Card";

const tabsData = [
  {
    key: "first",
    title: "Research & Discovery",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/1.png",
    numberText: "1",
    processName: "Research & Discovery",
    listContent: (
      <>
        <ul>
          <li>
            - We begin by understanding your brand, target audience, and project goals through in-depth research, ensuring a design that aligns with user needs and business objectives.
          </li>          
        </ul>
      </>
    ),
  },
  {
    key: "second",
    title: "Wireframing & Prototyping",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/2.png",
    numberText: "2",
    processName: "Wireframing & Prototyping",
    listContent: (
      <>
        <ul>
          <li>
            - Our team creates wireframes and interactive prototypes, focusing on layout, navigation, and user flow to ensure a smooth and intuitive user experience.
          </li>          
        </ul>
      </>
    ),
  },
  {
    key: "third",
    title: "UI Design & Visuals",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/3.png",
    numberText: "3",
    processName: "UI Design & Visuals",
    listContent: (
      <>
        <ul>
          <li>
            - We craft visually engaging designs, selecting colors, typography, and imagery that align with your brand identity while enhancing usability and aesthetic appeal.
          </li>          
        </ul>
      </>
    ),
  },
  {
    key: "fourth",
    title: "User Testing & Feedback",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/4.png",
    numberText: "4",
    processName: "User Testing & Feedback",
    listContent: (
      <>
        <ul>
          <li>
            - We conduct user testing to gather valuable feedback, refining designs based on real-user interactions to ensure the best possible user experience.
          </li>          
        </ul>
      </>
    ),
  },
  {
    key: "fifth",
    title: "Final Design & Handoff",
    imgSrc: "/assets/images/it-solutions/website-devlopment/proccess/5.png",
    numberText: "5",
    processName: "Final Design & Handoff",
    listContent: (
      <>
        <ul>
          <li>
            - Once the design is polished, we deliver all assets, documentation, and guidelines for a seamless transition to the development team, ensuring smooth implementation.
          </li>
          
        </ul>
      </>
    ),
  }
];

function Development_Process_Tab() {
  return (
    <section className="Development_Process_Tab">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className="row me-0 ms-0">
          <div className="col-lg-5 desktop-view-tab-holder">
            <Nav variant="pills" className="flex-column">
              {tabsData.map(({ key, title }) => (
                <Nav.Item key={key}>
                  <Nav.Link eventKey={key}>
                    <div className="tab-name-holder">
                      <p>{title}</p>
                      <div className="arrow-holder">
                        <FontAwesomeIcon
                          className="arrow-icon"
                          icon={faAngleRight}
                        />
                      </div>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>

          <div className="col-md-12 mobile-view-tab-holder">
            <Nav variant="pills" className="tabs-nav">
              <Swiper
                modules={[Navigation]}
                spaceBetween={10}
                slidesPerView={3} // Adjust based on how many tabs should be visible
                navigation
                pagination={{ clickable: true }}
                className="tabs-swiper"
                breakpoints={{
                  0: {
                    // For small screens
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },

                  486: {
                    // For small screens
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },

                  575: {
                    // For small screens
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },
                  768: {
                    // For medium screens
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                }}
              >
                {tabsData.map(({ key, title }) => (
                  <SwiperSlide key={key}>
                    <Nav.Item>
                      <Nav.Link eventKey={key}>
                        <div className="tab-name-holder">
                          <p>{title}</p>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Nav>
          </div>

          <div className="col-lg-7 col-md-12 tab-content-holder">
            <Tab.Content>
              {tabsData.map(
                ({ key, imgSrc, numberText, processName, listContent }) => (
                  <Tab.Pane eventKey={key} key={key}>
                    <Development_Process_Tab_Content_Card
                      imgSrc={imgSrc}
                      numberText={numberText}
                      proccesName={processName}
                      listContent={listContent}
                    />
                  </Tab.Pane>
                )
              )}
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </section>
  );
}

export default Development_Process_Tab;
