import React, { useEffect } from 'react'
import Career_bread from './career-bread/Career_bread'
import Car_details from './car-details/Car_details'


const Career_details = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Career_bread />
      <Car_details />
    </>
  )
}

export default Career_details