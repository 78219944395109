import React from "react";

function Development_Process_Title() {
  return (
    <section className="Development_Process_Title">
      <div className="container">
        <div className="text-center">
          <h1 className="whotitle">Awesome team members</h1>
          <h5 className="secsub-title">Our Web Development Process</h5>
        </div>
      </div>
    </section>
  );
}

export default Development_Process_Title;
