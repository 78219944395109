import React from 'react'
import './We_are_avers.css'
import Strategic from './animation/Strategic'
import Cutting_edge from './animation/Cutting_edge'
import Future_driven from './animation/Future_driven'

const We_are_avers = () => {
  return (
    <>
        <section className='We_are_avers'>
            <div className='container'>
                <div className='text-center'>
                    <p className='sec-title'>We are Avers</p>
                    <h5 className='secsub-title'>Business Development & Innovation</h5>
                </div>

                <div className='cardssec mt-5'>
                    <div className='row'>
                        <div className='col-lg-4 mb-4'>
                            <div className='busi_card'>
                                <div className='anim-div'>
                                    <Strategic />
                                </div>
                                <div className='cont-div'>
                                     <h6>Strategic Growth Solutions</h6>   
                                     <p>Developing customized strategies to empower businesses to grow, streamline operations, and succeed in ever-evolving markets</p>
                                </div>
                                <div className='overlaybg'></div>
                            </div>
                        </div>

                        <div className='col-lg-4 mb-4'>
                            <div className='busi_card'>
                                <div className='anim-div'>
                                    <Cutting_edge />
                                </div>
                                <div className='cont-div'>
                                     <h6>Cutting-Edge Technology Integration</h6>   
                                     <p>Leveraging advanced tools and innovations to streamline operations and deliver unparalleled efficiency</p>
                                </div>
                                <div className='overlaybg'></div>
                            </div>
                        </div>

                        <div className='col-lg-4 mb-4'>
                            <div className='busi_card'>
                                <div className='anim-div'>
                                    <Future_driven />
                                </div>
                                <div className='cont-div'>
                                     <h6>Future-Driven Innovation</h6>   
                                     <p>Anticipating tomorrow’s challenges with visionary ideas and sustainable solutions that drive progress</p>
                                </div>
                                <div className='overlaybg'></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default We_are_avers