import React from "react";
import Service_development_Card from "../../../Common_Component/Service_development_Card/Service_development_Card";

const Service_development = () => {
  return (
    <>
      <section className="Service_development_sec">
        <div className="container ">
          <div className="bg-servicedev">
            <div className="ttilebg">
              <div className="row">
                <div className="col-md-6 mb-md-0 mb-3">
                  <p className="devtit">Software Development</p>
                </div>
                <div className="col-md-6">
                  <p className="content">Our custom software development services are designed to streamline your operations, enhance productivity, and deliver lasting results.
                  </p>
                </div>
              </div>
            </div>

            <div className="Service_development_Card_Holder">
              <div className="row">
                <div className="col-xl-10 col-md-11 col-11 mx-auto">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/Software_Development/CustomSoftwareDevelopment.svg"} headingText={"Custom Software Development"} descriptionText={"Transform your unique ideas into reality with custom software. Tailored to your needs, our scalable, secure solutions drive business success."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 after_border_holder">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/Software_Development/erpsoftwaredevelopment.svg"} headingText={"ERP Software Development"} descriptionText={"Streamline operations with ERP software. Integrate processes like finance, HR, and supply chain into one powerful, user-friendly platform."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/Software_Development/CustomCRMSoftwareDevelopment.svg"} headingText={"Custom CRM Software Development"} descriptionText={"Enhance customer relationships with custom CRM software. Manage leads, automate workflows, and boost sales with a solution built for your business."} />
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service_development;
