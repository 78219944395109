import React, { useState } from 'react'
import './BlogsContent.css'
import { Link } from 'react-router-dom'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination } from 'react-bootstrap'

const BlogsContent = () => {

    const blogsData = [
        {
            title: "How AI is Revolutionizing Customer Support in 2024",
            date: "22 Nov 2024",
            image: "/assets/images/blogs/blogs/img1.png",
            descn: "Artificial Intelligence is no longer a futuristic concept it’s now the driving force behind customer support transformations. From chatbots to predictive analytics, learn how AI tools are enhancing customer experience while reducing operational costs."
        },
        {
            title: "How AI is Revolutionizing Customer Support in 2024",
            date: "22 Nov 2024",
            image: "/assets/images/blogs/blogs/img1.png",
            descn: "Artificial Intelligence is no longer a futuristic concept it’s now the driving force behind customer support transformations. From chatbots to predictive analytics, learn how AI tools are enhancing customer experience while reducing operational costs."
        },
        {
            title: "How AI is Revolutionizing Customer Support in 2024",
            date: "22 Nov 2024",
            image: "/assets/images/blogs/blogs/img1.png",
            descn: "Artificial Intelligence is no longer a futuristic concept it’s now the driving force behind customer support transformations. From chatbots to predictive analytics, learn how AI tools are enhancing customer experience while reducing operational costs."
        },
        {
            title: "How AI is Revolutionizing Customer Support in 2024",
            date: "22 Nov 2024",
            image: "/assets/images/blogs/blogs/img1.png",
            descn: "Artificial Intelligence is no longer a futuristic concept it’s now the driving force behind customer support transformations. From chatbots to predictive analytics, learn how AI tools are enhancing customer experience while reducing operational costs."
        },
        {
            title: "How AI is Revolutionizing Customer Support in 2024",
            date: "22 Nov 2024",
            image: "/assets/images/blogs/blogs/img1.png",
            descn: "Artificial Intelligence is no longer a futuristic concept it’s now the driving force behind customer support transformations. From chatbots to predictive analytics, learn how AI tools are enhancing customer experience while reducing operational costs."
        },
        {
            title: "How AI is Revolutionizing Customer Support in 2024",
            date: "22 Nov 2024",
            image: "/assets/images/blogs/blogs/img1.png",
            descn: "Artificial Intelligence is no longer a futuristic concept it’s now the driving force behind customer support transformations. From chatbots to predictive analytics, learn how AI tools are enhancing customer experience while reducing operational costs."
        },
        {
            title: "How AI is Revolutionizing Customer Support in 2024",
            date: "22 Nov 2024",
            image: "/assets/images/blogs/blogs/img1.png",
            descn: "Artificial Intelligence is no longer a futuristic concept it’s now the driving force behind customer support transformations. From chatbots to predictive analytics, learn how AI tools are enhancing customer experience while reducing operational costs."
        },
        {
            title: "How AI is Revolutionizing Customer Support in 2024",
            date: "22 Nov 2024",
            image: "/assets/images/blogs/blogs/img1.png",
            descn: "Artificial Intelligence is no longer a futuristic concept it’s now the driving force behind customer support transformations. From chatbots to predictive analytics, learn how AI tools are enhancing customer experience while reducing operational costs."
        }

    ]

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;

    // Calculate the sliced data for the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = blogsData.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(blogsData.length / itemsPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };



    return (
        <section className='BlogsContent'>

            <div className='row'>

                {currentData?.length > 0 && currentData?.map((blog) => {
                    return (
                        <div className='col-lg-6 mb-4'>
                            <Link to="/blog-detail">
                                <div className='blog_card_holder'>
                                    <div className='img_div'>
                                        <img className='blog_img' src={`${process.env.PUBLIC_URL}${blog?.image}`} />
                                        <div className='overlay_content'>
                                            <Link className='data' to="#">{blog.title}</Link>
                                        </div>
                                        <div className='overlay_date'>
                                            <p>- {blog.date}</p>
                                        </div>
                                        <div className='overlay_color'></div>
                                    </div>
                                    <div className='blog_text_container'>
                                        <p className='blog_text'>
                                            {blog.descn}
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                })}
            </div>

            <div className='pagination_div'>
                <Pagination className="justify-content-start main-pagination">
                    <Pagination.Prev
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} className="page-link-prev" />
                    </Pagination.Prev>

                    {Array.from({ length: totalPages }, (_, i) => (
                        <Pagination.Item
                            key={i + 1}
                            active={i + 1 === currentPage}
                            onClick={() => handlePageChange(i + 1)}
                        >
                            {i + 1 < 10 ? `0${i + 1}` : i + 1}
                        </Pagination.Item>
                    ))}

                    <Pagination.Next
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <FontAwesomeIcon icon={faArrowRight} className="page-link-next" />
                    </Pagination.Next>
                </Pagination>
            </div>


        </section>
    )
}

export default BlogsContent
