import React, { useEffect } from 'react'
import Tab_compo from './tab-compo/Tab_compo'
import Mobile_gaming from './mobile-gaming/Mobile_gaming'

const Portfolio = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Tab_compo />
      {/* <Mobile_gaming /> */}
    </>
  )
}

export default Portfolio