import React from "react";
import Service_development_Card from "../../../Common_Component/Service_development_Card/Service_development_Card";

const Service_development = () => {
  return (
    <>
      <section className="Service_development_sec">
        <div className="container ">
          <div className="bg-servicedev">
            <div className="ttilebg">
              <div className="row">
                <div className="col-md-6 mb-md-0 mb-3">
                  <p className="devtit">UI/UX Design</p>
                </div>
                <div className="col-md-6">
                  <p className="content">Our expert UI/UX designers combine creativity and functionality to build interfaces that enhance usability and deliver seamless experiences.</p>
                </div>
              </div>
            </div>

            <div className="Service_development_Card_Holder">
              <div className="row">
                <div className="col-xl-10 col-md-11 col-11 mx-auto">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/UIUX_Design_Services/ux-interface.svg"} headingText={"User Interface (UI) Design"} descriptionText={"Create visually stunning and intuitive interfaces that captivate users. Our designs ensure seamless navigation and a memorable digital experience."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 after_border_holder">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/UIUX_Design_Services/ui-design.svg"} headingText={"User Experience (UX) Design"} descriptionText={"Enhance user satisfaction with thoughtful UX design. We focus on usability, accessibility, and interaction to deliver smooth and engaging experiences."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/UIUX_Design_Services/WireframingandPrototyping.svg"} headingText={"Wireframing and Prototyping"} descriptionText={"Visualize your ideas with detailed wireframes and prototypes. Test and refine your concepts before final development for optimal results."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/UIUX_Design_Services/MobileAppDesign.svg"} headingText={"Mobile App Design"} descriptionText={"Deliver exceptional mobile experiences with designs tailored for Android and iOS platforms. We prioritize usability and aesthetic appeal."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 after_border_holder">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/UIUX_Design_Services/WebsiteDesign.svg"} headingText={"Website Design"} descriptionText={"Craft responsive and visually appealing websites. Our designs blend functionality with creativity to leave a lasting impression."} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <Service_development_Card imgSrc={"/assets/images/it-solutions/UIUX_Design_Services/usability.svg"} headingText={"Usability Testing"} descriptionText={"Ensure your product meets user expectations with comprehensive testing. We identify and resolve issues to enhance the overall user journey."} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service_development;
