import React from 'react'
import './Tech_innovation.css'
import { Link } from 'react-router-dom'

const Tech_innovation = () => {
    return (
        <>
            <section className='Tech_innovation_sec'>
                <div className='container-lg'>
                    <div className='text-center'>
                        <p className='sec-title'>Insights, Trends, & Solutions for a Smarter Tomorrow</p>
                        <h5 className='secsub-title'>Explore the Latest in Tech and Innovation</h5>
                    </div>

                    <div className='images-grids'>
                        <div className='row'>
                            <div className='col-md-4 col-sm-6 col-10 mx-auto mb-4'>
                                <div className='img-div'>
                                    <img className='tech-img' src={process.env.PUBLIC_URL + '/assets/images/home/innovation/ban1.png'} />
                                    <div className='overlay-cont'>
                                        <Link className='data' to="#">Top 5 IT Trends Transforming
                                            Businesses in 2024</Link>
                                    </div>
                                    <div className='overlay-color'></div>
                                </div>
                            </div>
                            <div className='col-md-4 col-sm-6 col-10 mx-auto mb-4'>
                                <div className='img-div'>
                                    <img className='tech-img' src={process.env.PUBLIC_URL + '/assets/images/home/innovation/ban2.png'} />
                                    <div className='overlay-cont'>
                                        <Link className='data' to="#">Case Study: How We Helped XYZ
                                            Corp Streamline Operations with
                                            AI Solutions</Link>
                                    </div>
                                    <div className='overlay-color'></div>
                                </div>
                                <div className='img-div'>
                                    <img className='tech-img' src={process.env.PUBLIC_URL + '/assets/images/home/innovation/ban3.png'} />
                                    <div className='overlay-cont'>
                                        <Link className='data' to="#">Cloud Computing: Why It’s Essential
                                            for Modern Enterprises</Link>
                                    </div>
                                    <div className='overlay-color'></div>
                                </div>
                            </div>
                            <div className='col-md-4 col-sm-6 col-10 mx-auto mb-4'>
                                <div className='img-div'>
                                    <img className='tech-img' src={process.env.PUBLIC_URL + '/assets/images/home/innovation/ban4.png'} />
                                    <div className='overlay-cont'>
                                        <Link className='data' to="#">How to Enhance Cybersecurity for
                                            Your Business in Simple Steps</Link>
                                    </div>
                                    <div className='overlay-color'></div>
                                </div>
                                <div className='img-div'>
                                    <img className='tech-img' src={process.env.PUBLIC_URL + '/assets/images/home/innovation/ban5.png'} />
                                    <div className='overlay-cont'>
                                        <Link className='data' to="#">Top 5 IT Trends Transforming
                                            Businesses in 2024</Link>
                                    </div>
                                    <div className='overlay-color'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Tech_innovation