import React from 'react'
import Form from 'react-bootstrap/Form';
import '../../contact-us/contact-form/contact_form.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Successful_modal from '../../Common_Component/modals/succcessful-modal/Successful_modal';

const Job_apply_form = () => {

    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>
            <h1 className='applytxt'>Apply for this Position</h1>
            <section className='Contact_form'>
                <Form>
                    <div className='row'>
                        <div className='col-lg-6 mb-4'>
                            <div className='form-group'>
                                <Form.Label>First Name*</Form.Label>
                                <Form.Control type="text" placeholder="First your First Name" />
                            </div>
                        </div>
                        <div className='col-lg-6 mb-4'>
                            <div className='form-group'>
                                <Form.Label>Last Name*</Form.Label>
                                <Form.Control type="text" placeholder="First your Last Name" />
                            </div>
                        </div>
                        <div className='col-lg-6 mb-4'>
                            <div className='form-group'>
                                <Form.Label>Email*</Form.Label>
                                <Form.Control type="text" placeholder="First your Email" />
                            </div>
                        </div>
                        <div className='col-lg-6 mb-4'>
                            <div className='form-group'>
                                <Form.Label>Contact Number*</Form.Label>
                                <Form.Control type="text" placeholder="First your Contact Number" />
                            </div>
                        </div>


                        <div className='col-lg-12 mb-4'>
                            <div className='form-group'>
                                <Form.Label>Cover Letter *</Form.Label>
                                <Form.Control style={{ height: '100px' }} as="textarea" placeholder="Type From here..." />
                            </div>
                        </div>

                        <div className='col-lg-6 mb-4'>
                            <div className='form-group'>
                                <Form.Label>Upload your Cv*</Form.Label>
                                <Form.Control type="file" placeholder="First your Contact Number" />
                            </div>
                        </div>

                        <div className='col-lg-6 my-auto mb-4'>
                            <div className='form-group'>
                                <p className='alwp'>Allowed Type(s): .pdf, .doc, .docx</p>
                            </div>
                        </div>
                        <div className='col-lg-12 mb-4'>
                            <div className='form-group'>
                                <Form>
                                    {['checkbox'].map((type) => (
                                        <div key={`default-${type}`} className="mb-3">
                                            <Form.Check 
                                                type={type}
                                                id={`default-${type}`}
                                                label="By using this form you agree with the storage and handling of your data by this website"
                                            />                                            
                                        </div>
                                    ))}
                                </Form>
                            </div>
                        </div>
                        <div className='col-lg-12 mb-4'>
                            <div className='form-group'>
                                <button className='submit-btn' type='button' onClick={() => setModalShow(true)}>Submit</button>
                            </div>
                        </div>
                    </div>
                </Form>
            </section>


            <Successful_modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        message = "Applied for job position Successfully!!"
      />
        </>
    )
}

export default Job_apply_form