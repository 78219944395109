import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faHouse } from '@fortawesome/free-solid-svg-icons';

const Service_breadcrumb = () => {
    return (
        <>
            <section className='Blog_bread_sec'>
                <div className='container'>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/"><FontAwesomeIcon icon={faHouse} /></Breadcrumb.Item>
                        <Breadcrumb.Item href="#"><FontAwesomeIcon icon={faChevronRight} />IT Solutions</Breadcrumb.Item>
                        <Breadcrumb.Item href="#" active><FontAwesomeIcon icon={faChevronRight} />Website Development</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </section>
        </>
    )
}

export default Service_breadcrumb