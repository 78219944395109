import React from 'react'
import '../../who-we-are/who-we/Who_we.css'

const Car_banner = () => {
    return (
        <>
            <section className='Who_we_sec'>
                <div className='container'>
                    <div className='titlediv'>
                        <div className='text-center'>
                            <h1 className='whotitle'>Career</h1>
                            <h5 className='secsub-title'>Build Your Future, Code Your Dreams</h5>
                        </div>

                        <img className='whoban' src={process.env.PUBLIC_URL + '/assets/images/career/banner/banner.png'} />

                       
                        <p className='contentt'>Join our dynamic team and shape the future of technology! At Profcyma , we value innovation, collaboration, and growth. Whether you're a seasoned professional or just starting your journey, we provide the tools, support, and opportunities to help you thrive in an ever-evolving IT landscape. Let’s build the future together!</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Car_banner