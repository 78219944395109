import React, { useRef, useState } from 'react';
import './It_solutions.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';

import { Pagination } from 'swiper/modules';


const It_solutions = () => {

    const solutions = [
        {
            name: "Website Development",
            image: "/assets/images/home/it-solutions/Artboard–1.png",
            link: "/website-developement"
        },
        {
            name: "App Development",
            image: "/assets/images/home/it-solutions/Artboard–2.png",
            link: "/app-developement"
        },
        {
            name: "Digital Marketing",
            image: "/assets/images/home/it-solutions/Artboard–3.png",
            link: "/digital-developement"
        },
        {
            name: "UI/UX Design",
            image: "/assets/images/home/it-solutions/Artboard–4.png",
            link: "/ui-ux-developement"
        },
        {
            name: "Software Development",
            image: "/assets/images/home/it-solutions/Artboard–5.png",
            link: "/software-developement"
        },
        {
            name: "Brand Awarenesss",
            image: "/assets/images/home/it-solutions/Artboard–6.png",
            link: "/brand-developement"
        },
        {
            name: "Graphics Designing",
            image: "/assets/images/home/it-solutions/Artboard–7.png",
            link: "/graphics-developement"
        },
        {
            name: "E-commerce Development",
            image: "/assets/images/home/it-solutions/Artboard–8.png",
            link: "/e-commerce-developement"
        },

    ];

    return (
        <>
            <section className='It_solutions_sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7 '>
                            <div className='text-start'>
                                <p className='sec-title'>Tailored IT Solutions</p>
                                <h5 className='secsub-title ms-0'>Crafting Tailored IT Solutions for your Business</h5>
                            </div>
                        </div>
                        <div className='col-lg-5 '>
                            <p className='itsol-cont'>At Avers, we deliver customized IT solutions tailored to your business needs. By understanding your challenges & goals, we implement technology that drives efficiency, scalability, & growth, helping you innovate and streamline operations for success</p>
                        </div>
                    </div>

                    <div className='slider-sec'>
                        <Swiper
                            pagination={{
                                dynamicBullets: true,
                                clickable: true,
                            }}
                            breakpoints={{
                                '0': {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                '350': {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                '575': {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                                '768': {
                                    slidesPerView: 4,
                                    spaceBetween: 10,
                                },
                                '1200': {
                                    slidesPerView: 4,
                                    spaceBetween: 10,
                                }
                                ,
                                '1400': {
                                    slidesPerView: 5,
                                    spaceBetween: 10,
                                }
                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                        >
                            {solutions.map((data, index) => (
                                <SwiperSlide>
                                    <Link to ={data.link}> 
                                        <div className='slider-div'>
                                            <img className='slide-img' src={process.env.PUBLIC_URL + data.image} />
                                            <p className='sol-name'>{data.name}</p>
                                            <div className='overlay-color'></div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                            ))}



                            {/* <SwiperSlide>
                                <div className='slider-div'>
                                    <img className='slide-img' src={process.env.PUBLIC_URL + '/assets/images/home/it-solutions/Manufracturuing.png'} />
                                    <p className='sol-name'>Manufracturuing</p>
                                    <div className='overlay-color'></div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='slider-div'>
                                    <img className='slide-img' src={process.env.PUBLIC_URL + '/assets/images/home/it-solutions/Healthcare.png'} />
                                    <p className='sol-name'>Healthcare</p>
                                    <div className='overlay-color'></div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='slider-div'>
                                    <img className='slide-img' src={process.env.PUBLIC_URL + '/assets/images/home/it-solutions/Education.png'} />
                                    <p className='sol-name'>Education</p>
                                    <div className='overlay-color'></div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='slider-div'>
                                    <img className='slide-img' src={process.env.PUBLIC_URL + '/assets/images/home/it-solutions/Travel&Tourism.png'} />
                                    <p className='sol-name'>Travel & Tourism</p>
                                    <div className='overlay-color'></div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='slider-div'>
                                    <img className='slide-img' src={process.env.PUBLIC_URL + '/assets/images/home/it-solutions/game.png'} />
                                    <p className='sol-name'>Gaming</p>
                                    <div className='overlay-color'></div>
                                </div>
                            </SwiperSlide> */}

                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    )
}

export default It_solutions